import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import iEnergipresentationFilterModel from './iEnergipresentationFilter';
import iEnergipresentationYearsModel from './iEnergipresentationYears';
import iEnergipresentationMinMaxYearsModel from './iEnergipresentationMinMaxYears';
import EventBus from '../EventBus';

function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null);

    const setStateCallback = useCallback((state, callback) => {
        cbRef.current = callback;
        setState(state);
    }, []);

    useEffect(() => {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
        }
    }, [state]);

    return [state, setStateCallback];
};

interface iProps {
    title: string;
    textField: string;
    bubblesBlockCount: number;
    linkedBlockId: string;
    year1DefaultValue: number;
    year2DefaultValue: number;
    firstLoad: boolean;
    label1: string;
    label2: string;
    singleSelect: boolean;
};

function isElementInView(element) {
    const bounding = element.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

function isScrollable(element) {
    return element && element.clientHeight < element.scrollHeight;
};

function maintainScrollVisibility(activeElement, scrollParent) {
    const { offsetHeight, offsetTop } = activeElement;
    const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent;

    const isAbove = offsetTop < scrollTop;
    const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

    if (isAbove) {
        scrollParent.scrollTo(0, offsetTop);
    } else if (isBelow) {
        scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
    }
};


const EnergipresentationFilter = (props: iProps) => {
    const initialValues: iEnergipresentationFilterModel = {
        title: props.title,
        textField: props.textField,
        firstYear: -1,
        secondYear: -1,
        selectableYears: [],
        showFirstYearOptions: false,
        showSecondYearOptions: false,
        bubblesBlockCount: props.bubblesBlockCount,
        loadedBubbleBlocks: 0,
        linkedBlockId: props.linkedBlockId,
        maxYear: -1,
        minYear: -1,
        isLoading: true,
        year1DefaultValue: props.year1DefaultValue,
        year2DefaultValue: props.year2DefaultValue,
        firstLoad: props.firstLoad,
        label1: props.label1,
        label2: props.label2,
        singleSelect: props.singleSelect
    };

    const [state, setState] = useStateCallback(initialValues);
    const firstYearRef = useRef(null);
    const secondYearRef = useRef(null);
    const [showFirstYearOptions, setShowFirstYearOptions] = useState(false);
    const [showSecondYearOptions, setShowSecondYearOptions] = useState(false);
    const [focusedOptionIndex, setFocusedOptionIndex] = useState(0);
    const [selectedYearIndex, setSelectedYearIndex] = useState(null);
    const dropdownOptionsRef = useRef([]);


    useEffect(() => {
        if (showFirstYearOptions && selectedYearIndex !== null) {
            const selectedOption = document.querySelector(`#option-${selectedYearIndex}`);
            if (selectedOption) {
                selectedOption.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }
    }, [showFirstYearOptions, selectedYearIndex]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (state.showFirstYearOptions && firstYearRef.current && !firstYearRef.current.contains(event.target)) {
                toggleFirstYearOptions();
            }
            if (state.showSecondYearOptions && secondYearRef.current && !secondYearRef.current.contains(event.target)) {
                toggleSecondYearOptions();
            }
        };

        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [state.showFirstYearOptions, state.showSecondYearOptions]);

    useEffect(() => {
        setFirstLoad(state.firstLoad);

        if (state.linkedBlockId === "") {
            EventBus.on("LoadedBubblesBlock", loadedBlock);
            return;
        }
        EventBus.on("LoadedBlock_" + state.linkedBlockId, loadedBlock);
        EventBus.on("ToggleBlock_" + state.linkedBlockId, toggleFilter);
    }, []);

    const getFilterBlock = (): HTMLElement => {
        let filterBlock: HTMLElement = document.getElementById("EnergipresentationFilterBlock_" + state.linkedBlockId);
        return filterBlock ?? new HTMLElement();
    };

    const setLoadedBubblesInMarkup = (loadedBubblesCount: number): void => {
        let filterBlock = getFilterBlock();
        filterBlock.setAttribute("data-loaded-bubbles-count", loadedBubblesCount.toString());
    };
    const getLoadedBubblesInMarkup = (): number => {
        let filterBlock = getFilterBlock();
        let loadedBubblesCountString = filterBlock.getAttribute("data-loaded-bubbles-count");

        if (loadedBubblesCountString === null
            || loadedBubblesCountString === undefined
            || loadedBubblesCountString === "") {
            return 0;
        }

        let loadedBubblesCount = parseInt(loadedBubblesCountString);

        if (isNaN(loadedBubblesCount)) {
            return 0;
        }

        return loadedBubblesCount;
    };

    const loadedBlock = (years: iEnergipresentationMinMaxYearsModel): void => {
        let newState = { ...state };

        if (state.linkedBlockId === "") {
            let loadedBubblesBlocks = getLoadedBubblesInMarkup();
            loadedBubblesBlocks = loadedBubblesBlocks + 1;
            newState.loadedBubbleBlocks = loadedBubblesBlocks;
            setLoadedBubblesInMarkup(newState.loadedBubbleBlocks);
        }

        let markupMinYear = getMinMaxYear(true);
        let markupMaxYear = getMinMaxYear(false);

        if (newState.minYear > markupMinYear || newState.minYear === -1) {
            newState.minYear = markupMinYear;
        }

        if (newState.maxYear < markupMaxYear || newState.maxYear === -1) {
            newState.maxYear = markupMaxYear;
        }

        if (years !== null && years !== undefined && years.minYear > -1 && years.maxYear > -1) {
            let updatedYears = false;

            if (years.minYear < newState.minYear || (newState.minYear === -1)) {
                newState.minYear = years.minYear;
                updatedYears = true;
            }

            if (years.maxYear > newState.maxYear) {
                newState.maxYear = years.maxYear;
                updatedYears = true;
            }

            if (updatedYears) {
                newState.selectableYears = generateYears(newState.minYear, newState.maxYear);
            }
        }

        if (newState.selectableYears === undefined || newState.selectableYears === null || newState.selectableYears.length === 0) {
            newState.selectableYears = generateYears(newState.minYear, newState.maxYear);
        }

        if (state.linkedBlockId !== "" || newState.loadedBubbleBlocks === newState.bubblesBlockCount) {
            newState.isLoading = false;
            newState.firstYear = newState.minYear;
            if (!state.singleSelect) {
                newState.secondYear = newState.maxYear;
            }
            newState.firstLoad = getFirstLoad();

            if (useFirstLoad(newState)) {
                newState.firstYear = newState.year1DefaultValue;
                if (!state.singleSelect) {
                    newState.secondYear = newState.year2DefaultValue;
                }
            }
            else if (newState.selectableYears.length >= 2) {
                newState.firstYear = newState.selectableYears[newState.selectableYears.length - 1];
                if (!state.singleSelect) {
                    newState.secondYear = newState.selectableYears[newState.selectableYears.length - 2];
                }
            }

            newState.firstLoad = false;
            setFirstLoad(newState.firstLoad);
            setState(newState, updateBlockYears);
        }
        else {
            setState(newState);
        }

        setMinMaxYear(true, newState.minYear);
        setMinMaxYear(false, newState.maxYear);

        //if (newState.selectableYears.length === 1 && newState.selectableYears[0] === -1) {
        //    hideFilter();
        //}
    };

    const toggleFilter = (d:any): void => {
        let filterBlock = getFilterBlock();
        if (d == null) {
            filterBlock.classList.add("hidden");
        }
        else {
            filterBlock.classList.remove("hidden");
        }
    };

    const useFirstLoad = (newState: iEnergipresentationFilterModel): boolean => {
        let year1DefaultExists = newState.year1DefaultValue !== null && newState.selectableYears.includes(newState.year1DefaultValue);
        let year2DefaultExists = newState.year2DefaultValue !== null && newState.selectableYears.includes(newState.year2DefaultValue);
        let useFirstLoad = newState.firstLoad &&
            newState.year1DefaultValue !== null && year1DefaultExists &&
            (state.singleSelect || (newState.year2DefaultValue !== null && year2DefaultExists));
        return useFirstLoad;
    };

    const updateBlockYears = (savedState: iEnergipresentationFilterModel): void => {
        let selectedYears: iEnergipresentationYearsModel = { firstYear: savedState.firstYear };
        if (!savedState.singleSelect) {
            selectedYears.secondYear = savedState.secondYear;
        }
        EventBus.dispatch("UpdateBlockYears_" + savedState.linkedBlockId, selectedYears);
    };

    const setMinMaxYear = (isMinYear: boolean, year: number): void => {
        let attributeName = isMinYear ? "data-min-year" : "data-max-year";
        let filterBlock = getFilterBlock();
        filterBlock.setAttribute(attributeName, year.toString());
    };

    const getMinMaxYear = (isMinYear: boolean): number => {
        let attributeName = isMinYear ? "data-min-year" : "data-max-year";
        let filterBlock = getFilterBlock();
        let yearString = filterBlock.getAttribute(attributeName);

        if (yearString === null
            || yearString === undefined
            || yearString === "") {
            return -1;
        }

        let year = parseInt(yearString);
        if (isNaN(year)) {
            return -1;
        }

        return year;
    };

    const setFirstLoad = (firstLoad: boolean): void => {
        let filterBlock = getFilterBlock();
        filterBlock.setAttribute("data-first-load", firstLoad.toString());
    };

    const getFirstLoad = (): boolean => {
        let filterBlock = getFilterBlock();
        let firstLoadString = filterBlock.getAttribute("data-first-load");
        let firstLoad = firstLoadString == null || firstLoadString.toLowerCase() === "true";
        return firstLoad;
    };

    const generateYears = (minYear: number, maxYear: number): number[] => {
        let yearOptions: number[] = [];

        for (let year = minYear; year <= maxYear; year++) {
            yearOptions.push(year);
        }

        yearOptions.sort((a, b) => b - a);

        return yearOptions;
    };

    const toggleFirstYearOptions = (): void => {
        setState((prevState) => {
            const newState = { ...prevState, showFirstYearOptions: !prevState.showFirstYearOptions };

            if (prevState.showFirstYearOptions) {
                return newState;
            }

            setTimeout(() => {
                if (dropdownOptionsRef.current[focusedOptionIndex]) {
                    dropdownOptionsRef.current[focusedOptionIndex].scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                }
            }, 0);
            return newState;
        });
    };

    const toggleSecondYearOptions = (): void => {
        setState((prevState) => {
            const newState = { ...prevState, showSecondYearOptions: !prevState.showSecondYearOptions };

            if (prevState.showSecondYearOptions) {
                return newState;
            }

            setTimeout(() => {
                if (dropdownOptionsRef.current[focusedOptionIndex]) {
                    dropdownOptionsRef.current[focusedOptionIndex].scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                }
            }, 0);
            return newState;
        });
    };

    const toggleDropdownOptions = (dropdown): void => {
        setState((prevState) => {
            const newState = { ...prevState, [dropdown]: !prevState[dropdown] };

            if (prevState[dropdown]) {
                return newState;
            }

            setTimeout(() => {
                if (dropdownOptionsRef.current[focusedOptionIndex]) {
                    dropdownOptionsRef.current[focusedOptionIndex].scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                }
            }, 0);
            return newState;
        });
    };

    const changeFirstYear = (newYear, index = -1): void => {
        setSelectedYearIndex(index);
        let newState = { ...state };
        newState.showFirstYearOptions = false;
        newState.firstYear = newYear;
        setState(newState);
        if (index !== -1) {
            setFocusedOptionIndex(index);
        }
        updateBlockYears(newState);
    };

    const changeSecondYear = (newYear, index = -1): void => {
        setSelectedYearIndex(index);
        let newState = { ...state };
        newState.showSecondYearOptions = false;
        newState.secondYear = newYear;
        if (index !== -1) {
            setFocusedOptionIndex(index);
        }
        setState(newState);
        updateBlockYears(newState);
    };

    const createHandleKeyDown = (dropdownId: 'showFirstYearOptions' | 'showSecondYearOptions'): ((e: React.KeyboardEvent<HTMLDivElement>) => void) => {
        return (e: React.KeyboardEvent<HTMLDivElement>) => {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    if (!state[dropdownId]) {
                        toggleDropdownOptions(dropdownId);
                    } else {
                        setFocusedOptionIndex((prevIndex) => {
                            const newIndex = Math.min(prevIndex + 1, state.selectableYears.length - 1);
                            scrollFocusedOptionIntoView(newIndex);
                            return newIndex;
                        });
                    }
                    break;

                case 'ArrowUp':
                    e.preventDefault();
                    if (!state[dropdownId]) {
                        toggleDropdownOptions(dropdownId);
                    } else {
                        setFocusedOptionIndex((prevIndex) => {
                            const newIndex = Math.max(prevIndex - 1, 0);
                            scrollFocusedOptionIntoView(newIndex);
                            return newIndex;
                        });
                    }
                    break;

                case 'Enter':
                case ' ':
                    e.preventDefault();
                    if (!state[dropdownId]) {
                        toggleDropdownOptions(dropdownId);
                    } else {
                        const selectedYear = state.selectableYears[focusedOptionIndex];
                        dropdownId === "showFirstYearOptions"
                            ? changeFirstYear(selectedYear, focusedOptionIndex)
                            : changeSecondYear(selectedYear);

                        setState((prevState) => ({
                            ...prevState,
                            [dropdownId]: false,
                        }));
                    }
                    break;

                case 'Escape':
                    e.preventDefault();
                    if (state[dropdownId]) {
                        toggleDropdownOptions(dropdownId);
                    }
                    break;

                case 'Home':
                    e.preventDefault();
                    if (state[dropdownId]) {
                        setFocusedOptionIndex(0);
                    }
                    break;

                case 'End':
                    e.preventDefault();
                    if (state[dropdownId]) {
                        setFocusedOptionIndex(state.selectableYears.length - 1);
                    }
                    break;

                default:
                    break;
            }
        };
    };

    const scrollFocusedOptionIntoView = (index: number): void => {
        const optionElement = dropdownOptionsRef.current[index];
        const dropdownContainer = optionElement?.parentNode;

        if (optionElement && dropdownContainer) {
            if (isScrollable(dropdownContainer)) {
                maintainScrollVisibility(optionElement, dropdownContainer);
            }
            if (!isElementInView(optionElement)) {
                optionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    };

    return (
        <React.Fragment>
            <h3>{state.title}</h3>
            <div className="body">
                <div className="bodytext is-block" dangerouslySetInnerHTML={{ __html: state.textField }} />
            </div>
            <div className="filter-container">
                {state.isLoading && (
                    <div className="loader-filter-container">
                        <div className="loader"></div>
                    </div>
                )}
                <div className="year-container">
                    <div id="combo1-label1" className="label-text">{state.label1}</div>
                    {state.isLoading && (
                        <div className="dropdown-container-loading">
                            <div className="dropdown-placeholder">
                            </div>
                        </div>
                    )}
                    {!state.isLoading && (
                        <div className="dropdown-container custom-select" ref={firstYearRef}
                        aria-controls="listbox1"
                        aria-expanded={state.showFirstYearOptions}
                        aria-haspopup="listbox"
                        aria-labelledby="combo1-label1"
                        id="combo1"
                        role="combobox"
                        aria-activedescendant={state.showFirstYearOptions ? `option-${focusedOptionIndex}` : undefined}
                        tabIndex={0}
                        onKeyDown={createHandleKeyDown('showFirstYearOptions')}>


                            <div className={state.showFirstYearOptions ? "dropdown-selected expanded" : "dropdown-selected"} onClick={() => { toggleFirstYearOptions() }}>
                                <div className="selected-value">{state.firstYear}</div>
                                <span className="glyphicon glyphicon-chevron-down"></span>
                            </div>


                            {state.showFirstYearOptions && (
                                <div className="dropdown-options-container"
                                    role="listbox"
                                    id="listbox1"
                                    aria-labelledby="combo1-label1"
                                    tabIndex={-1}>
                                    {
                                        state.selectableYears.map((selectableYear, selectableYearIndex) => {
                                            return (
                                                <div
                                                    role="option"
                                                    key={selectableYearIndex}
                                                    ref={(el) => (dropdownOptionsRef.current[selectableYearIndex] = el)}
                                                    id={`option-${selectableYearIndex}`}
                                                    className={`dropdown-option-item ${selectableYearIndex === focusedOptionIndex ? 'focused' : ''}`}
                                                    aria-selected={selectableYearIndex === focusedOptionIndex}
                                                    onKeyDown={createHandleKeyDown('showFirstYearOptions')}
                                                    onClick={() => changeFirstYear(selectableYear, selectableYearIndex)}
                                                >
                                                    {selectableYear}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {!state.singleSelect && (
                    <div className="year-container">
                        <div id="combo2-label2" className="label-text">{state.label2}</div>
                        {state.isLoading && (
                            <div className="dropdown-container-loading">
                                <div className="dropdown-placeholder">
                                </div>
                            </div>
                        )}
                        {!state.isLoading && (
                            <div className="dropdown-container custom-select" ref={secondYearRef}
                                aria-controls="listbox2"
                                aria-expanded={state.showSecondYearOptions}
                                aria-haspopup="listbox"
                                aria-labelledby="combo2-label2"
                                id="combo2"
                                role="combobox"
                                aria-activedescendant={state.showSecondYearOptions ? `option-${focusedOptionIndex}` : undefined}
                                tabIndex={0}
                                onKeyDown={createHandleKeyDown('showSecondYearOptions')}>
                                <div className={state.showSecondYearOptions ? "dropdown-selected expanded" : "dropdown-selected"} onClick={() => { toggleSecondYearOptions() }}>
                                    <div className="selected-value">{state.secondYear}</div>
                                    <span className="glyphicon glyphicon-chevron-down"></span>
                                </div>
                                {state.showSecondYearOptions && (
                                    <div className="dropdown-options-container"
                                        role="listbox"
                                        id="listbox2"
                                        aria-labelledby="combo2-label2"
                                        tabIndex={-1}>
                                        {
                                            state.selectableYears.map((selectableYear, selectableYearIndex) => {
                                                return (
                                                    <div
                                                        role="option"
                                                        key={selectableYearIndex}
                                                        ref={(el) => (dropdownOptionsRef.current[selectableYearIndex] = el)}
                                                        id={`option-${selectableYearIndex}`}
                                                        className={`dropdown-option-item ${selectableYearIndex === focusedOptionIndex ? 'focused' : ''}`}
                                                        aria-selected={selectableYearIndex === focusedOptionIndex}
                                                        onKeyDown={createHandleKeyDown('showSecondYearOptions')}
                                                        onClick={() => changeSecondYear(selectableYear)}
                                                    >
                                                        {selectableYear}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};
export default EnergipresentationFilter;