import * as React from 'react';
import { useState, useEffect } from 'react';
import EventBus from '../EventBus';
import iEnergipresentationBubbleApiRequestModel from './RequestModels/iEnergipresentationBubbleApiRequest';
import iEnergipresentationRequestModel from './RequestModels/iEnergipresentationRequest';
import iEnergipresentationBubblesModel from './iEnergipresentationBubbles';
import iEnergipresentationBubblesPropsModel from './iEnergipresentationBubblesProps';
import iEnergipresentationYearsModel from './iEnergipresentationYears';
import DataHelper from './DataHelper';
import iGraphDataOverview from './ResponseModels/iGraphDataOverview';
import { iEnergipresentationMinMaxYearsModel } from './iEnergipresentationMinMaxYears';
import iXAxisDictionary from './ResponseModels/iXAxisDictionary';


const EnergipresentationBubbles = (props: iEnergipresentationBubblesPropsModel) => {
    const initialState: iEnergipresentationBubblesModel = {
        headTitle: props.headTitle,

        apiUrl: props.apiUrl,
        requestBody: props.requestBody,
        xAxisVariableIndex: props.xAxisVariableIndex,
        categoryVariableIndex: props.categoryVariableIndex,

        prognoseApiUrl: props.prognoseApiUrl,
        prognoseRequestBody: props.prognoseRequestBody,
        prognoseXAxisVariableIndex: props.prognoseXAxisVariableIndex,
        prognoseCategoryVariableIndex: props.prognoseCategoryVariableIndex,
        prognoseDivideValueBy: props.prognoseDivideValueBy,
        prognoseText: props.prognoseText,

        isLoading: true,
        firstYear: -1,
        firstYearValue: 0,
        secondYear: -1,
        secondYearValue: 0,
        valuePrefix: props.valuePrefix,
        values: null,
        valueSuffix: props.valueSuffix,
        divideValueBy: props.divideValueBy,
        blockId: props.blockId,
        lastPublished: props.lastPublished,
        useCommonFilter: props.useCommonFilter
    };

    const [delayed, setDelayed] = useState(true);
    const [state, setState] = useState<iEnergipresentationBubblesModel>(initialState);

    useEffect(() => {
        setYears();

        if (state.useCommonFilter) {
            EventBus.on("UpdateBlockYears_", updateSelectedYears);
            return;
        }
        EventBus.on("UpdateBlockYears_" + state.blockId, updateSelectedYears);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 3000);
        return () => clearTimeout(timeout);
    }, []);

    const updateSelectedYears = async (years: iEnergipresentationYearsModel, values: iXAxisDictionary = null, prognoseStartYear: number = 0) => {
        let newState = { ...state };
        newState.prognoseStartYear = prognoseStartYear;

        if (newState.values === null && values === null) {
            let customNewYears: iEnergipresentationYearsModel = { firstYear: years.firstYear, secondYear: years.secondYear };
            await getGraphOverViewData(customNewYears, updateSelectedYearFromApi);
            return;
        } else if (newState.values === null && values !== null) {
            newState.values = values;
        }

        newState.firstYear = years.firstYear;
        newState.secondYear = years.secondYear;

        if (newState.values.hasOwnProperty(newState.firstYear)) {
            newState.firstYearValue = newState.values[newState.firstYear];
        } else {
            newState.firstYearValue = null;
        }

        if (newState.values.hasOwnProperty(newState.secondYear)) {
            newState.secondYearValue = newState.values[newState.secondYear];
        } else {
            newState.secondYearValue = null;
        }

        newState.isLoading = false;
        setState(newState);
    };

    const updateSelectedYearFromApi = async (data: iGraphDataOverview, years: iEnergipresentationYearsModel) => {
        await updateSelectedYears(years, data.values, data.prognoseStartYear);
    };

    const getGraphOverViewData = async (newYears: iEnergipresentationYearsModel, callback) => {
        let apiRequest1: iEnergipresentationRequestModel = {
            apiUrl: state.apiUrl,
            requestBody: state.requestBody,
            xAxisVariableIndex: state.xAxisVariableIndex,
            categoryVariableIndex: state.categoryVariableIndex,
            divideValueBy: 1,
            isPrognose: false,
        };
        let apiRequest2: iEnergipresentationRequestModel = {
            apiUrl: state.prognoseApiUrl,
            requestBody: state.prognoseRequestBody,
            xAxisVariableIndex: state.prognoseXAxisVariableIndex,
            categoryVariableIndex: state.prognoseCategoryVariableIndex,
            divideValueBy: state.prognoseDivideValueBy,
            isPrognose: true,
        };
        let apiRequest: iEnergipresentationBubbleApiRequestModel = {
            requests: [apiRequest1, apiRequest2].filter((r) => r.apiUrl.length !== 0),
            blockId: state.blockId,
            lastPublished: state.lastPublished
        };

        await DataHelper.FetchGraphOverviewData(apiRequest, callback, newYears);
    };

    const initializeBubbles = (data: iGraphDataOverview, newYears?: iEnergipresentationYearsModel) => {
        if (!data) {
            EventBus.dispatch("ToggleBlock_" + state.blockId, null);
            return;
        }

        let newState = { ...state };
        newState.values = data.values;
        newState.prognoseStartYear = data.prognoseStartYear;

        newState.firstYearValue = newState.values[newYears.firstYear];
        newState.secondYearValue = newState.values[newYears.secondYear];
        newState.firstYear = newYears.firstYear;
        newState.secondYear = newYears.secondYear;
        newState.isLoading = false;

        setState(newState);

        let objectKeys = Object.keys(newState.values);
        let minYearKey = objectKeys[0];
        let maxYearKey = objectKeys[objectKeys.length - 1];
        let minYear: number = minYearKey !== undefined && minYearKey !== null ? parseInt(minYearKey) : -1;
        let maxYear: number = maxYearKey !== undefined && maxYearKey !== null ? parseInt(maxYearKey) : -1;
        let currentYears: iEnergipresentationMinMaxYearsModel = { minYear: minYear, maxYear: maxYear };

        if (newState.useCommonFilter) {
            EventBus.dispatch("LoadedBubblesBlock", currentYears);
            return;
        }
        EventBus.dispatch("LoadedBlock_" + newState.blockId, currentYears);
    };

    const setYears = async (newYears?: iEnergipresentationYearsModel) => {
        if (newYears === undefined || newYears === null) {
            newYears = { firstYear: -1, secondYear: -1 };
        }

        await getGraphOverViewData(newYears, initializeBubbles);
    };

    const transformBubbleValue = (value: number): string => {
        let divideValueBy = state.divideValueBy;

        if (divideValueBy === null || divideValueBy === undefined || divideValueBy === 0) {
            divideValueBy = 1;
        }

        let roundedValue = Math.round(value / divideValueBy).toString();
        return roundedValue;
    };

    return (
        <React.Fragment>
            <h3>{state.headTitle}</h3>
            {state.values == null && !delayed && (
                <div>
                    <p><span className="label label-danger">Fel vid rendering av bubbelblock</span></p>
                    <p><strong>URL:</strong> <code>{state.apiUrl}</code></p>
                    <p><strong>RequestBody:</strong> <code>{state.requestBody}</code></p>
                    <p><strong>URL2:</strong> <code>{state.prognoseApiUrl}</code></p>
                    <p><strong>RequestBody2:</strong> <code>{state.prognoseRequestBody}</code></p>
                </div>
            )}
            {state.isLoading && state.values != null && (
                <div className="loading-bubbles-container">
                    <div className="loader-bubbles-container">
                        <div className="loader"></div>
                    </div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                </div>
            )}
            {!state.isLoading && state.values != null && (
                <div className="bubbles-container">
                    <div className={state.firstYearValue > state.secondYearValue ? "first-bubble is-highest" : "first-bubble"}>
                        <div className="year-title">
                            {state.firstYear}
                        </div>
                        {state.firstYearValue !== null && (
                            <div className="year-value">
                                {state.valuePrefix} {transformBubbleValue(state.firstYearValue)} {state.valueSuffix}
                            </div>
                        )}
                        {state.firstYearValue === null && (
                            <div className="year-value">
                                -
                            </div>
                        )}
                        {state.firstYearValue !== null && state.prognoseStartYear != 0 && state.firstYear >= state.prognoseStartYear && (
                            <div className="year-prognose">
                                {state.prognoseText}
                            </div>
                        )}
                    </div>
                    <div className={state.secondYearValue > state.firstYearValue ? "second-bubble is-highest" : "second-bubble"}>
                        <div className="year-title">
                            {state.secondYear}
                        </div>
                        {state.secondYearValue !== null && (
                            <div className="year-value">
                                {state.valuePrefix} {transformBubbleValue(state.secondYearValue)} {state.valueSuffix}
                            </div>
                        )}
                        {state.secondYearValue === null && (
                            <div className="year-value">
                                -
                            </div>
                        )}
                        {state.secondYearValue !== null && state.prognoseStartYear != 0 && state.secondYear >= state.prognoseStartYear && (
                            <div className="year-prognose">
                                {state.prognoseText}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment >
    );
};
export default EnergipresentationBubbles;
