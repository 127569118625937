import { iMobileTopBar } from "./iMobileTopBar";

//export const getCurrentMenuItems = (callback) => {
//    try {
//        let ajax = fetch("/MobileMenu/GetCurrentMenuItems?url=" + window.location.pathname, {
//            method: "GET",
//            headers: {
//                'Accept': 'application/json',
//                'Content-Type': 'application/json',
//            }
//        })
//            .then(response => response.json()
//                .then((json) => {

//                    if (!response.ok) {
//                        throw Error("Något gick fel: " + json.ExceptionMessage);
//                    }
//                    else {
//                        callback(json);
//                    }
//                })
//                .catch((err) => {
//                    console.error("Något gick fel: ", err);
//                    callback();
//                })
//            )
//    }
//    catch (err) {
//        console.error("Något gick fel: ", err);
//        callback();
//    }
//}

//export const getChildMenuItems = (pageId, callback) => {
//    try {
//        let ajax = fetch("/MobileMenu/GetChildren?pageId=" + pageId, {
//            method: "GET",
//            headers: {
//                'Accept': 'application/json',
//                'Content-Type': 'application/json',
//            }
//        })
//            .then(response => response.json()
//                .then((json) => {

//                    if (!response.ok) {
//                        throw Error("Något gick fel: " + json.ExceptionMessage);
//                    }
//                    else {
//                        callback(json);
//                    }
//                })
//                .catch((err) => {
//                    console.error("Något gick fel: ", err);
//                    callback();
//                })
//            )
//    }
//    catch (err) {
//        console.error("Något gick fel: ", err);
//        callback();
//    }
//}

//export const getParentMenuItems = (pageId, callback) => {
//    try {
//        let ajax = fetch("/MobileMenu/GetParents?pageId=" + pageId, {
//            method: "GET",
//            headers: {
//                'Accept': 'application/json',
//                'Content-Type': 'application/json',
//            }
//        })
//            .then(response => response.json()
//                .then((json) => {

//                    if (!response.ok) {
//                        throw Error("Något gick fel: " + json.ExceptionMessage);
//                    }
//                    else {
//                        callback(json);
//                    }
//                })
//                .catch((err) => {
//                    console.error("Något gick fel: ", err);
//                    callback();
//                })
//            )
//    }
//    catch (err) {
//        console.error("Något gick fel: ", err);
//        callback();
//    }
//}

export const getSearchSuggestions = (searchTerm, searchProxyUrl, searchTags, callback) => {
    try {
        let ajax = fetch(searchProxyUrl + "/_autocomplete?prefix=" + encodeURIComponent(searchTerm) + "&size=10&tags=" + encodeURIComponent(searchTags),
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json()
                .then((json) => {
                    if (!response.ok) {
                        throw Error("Något gick fel: " + json.ExceptionMessage);
                    }
                    else {
                        callback(json);
                    }
                })
                .catch((err) => {
                    console.error("Något gick fel: ", err);
                    callback();
                })
            )
    }
    catch (err) {
        console.error("Något gick fel: ", err);
        callback();
    }
}

export const getFullMenu = (callback) => {
    try {
        let ajax = fetch("/MobileMenu/GetFullMenu?url=" + window.location.pathname, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json()
                .then((json) => {
                    if (!response.ok) {
                        throw Error("Något gick fel: " + json.ExceptionMessage);
                    }
                    else {
                        callback(json);
                    }
                })
                .catch((err) => {
                    console.error("Något gick fel: ", err);
                    callback();
                })
            )
    }
    catch (err) {
        console.error("Något gick fel: ", err);
        callback();
    }
}