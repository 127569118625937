import * as React from 'react';
import { useState, useEffect } from 'react';
import EnergipresentationAccordion from './EnergipresentationAccordion';
import iEnergipresentationDoubleGraphModel from './iEnergipresentationDoubleGraph';
import iEnergipresentationDoubleGraphPropsModel from './iEnergipresentationDoubleGraphProps';
import iEnergipresentationDoubleSourceGraphApiRequestModel from './RequestModels/iEnergipresentationDoubleSourceGraphApiRequest';
import iEnergipresentationRequestModel from './RequestModels/iEnergipresentationRequest';
import DataHelper from './DataHelper';
import DisplayHelper from './DisplayHelper';
import iGraphData from './ResponseModels/iGraphData';
import * as Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import highchartsAccessibility from "highcharts/modules/accessibility";
import EnergipresentationExportButton from './EnergipresentationExportButton';
import EventBus from '../EventBus';

const EnergipresentationDoubleGraph = (props: iEnergipresentationDoubleGraphPropsModel) => {

    const [delayed, setDelayed] = useState(true);
    const [state, setState] = useState<iEnergipresentationDoubleGraphModel>(props);

    useEffect(() => {
        patternFill(Highcharts);
        highchartsAccessibility(Highcharts);
        getGraphData();
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 3000);
        return () => clearTimeout(timeout);
    }, []);

    const getGraphData = async () => {
        let partApiRequest1: iEnergipresentationRequestModel = {
            apiUrl: state.apiUrlOne,
            requestBody: state.requestBodyOne,
            xAxisVariableIndex: state.xAxisVariableIndex,
            categoryVariableIndex: state.categoryVariableIndex,
            isPrognose: false,
        };

        let partApiRequest2: iEnergipresentationRequestModel = {
            apiUrl: state.apiUrlTwo,
            requestBody: state.requestBodyTwo,
            xAxisVariableIndex: state.xAxisVariableIndex,
            categoryVariableIndex: state.categoryVariableIndex,
            isPrognose: false,
        };

        let partApiRequest3: iEnergipresentationRequestModel = {
            apiUrl: state.prognoseApiUrl,
            requestBody: state.prognoseRequestBody,
            xAxisVariableIndex: state.prognoseXAxisVariableIndex,
            categoryVariableIndex: state.prognoseCategoryVariableIndex,
            isPrognose: true,
        };

        let apiRequest: iEnergipresentationDoubleSourceGraphApiRequestModel = {
            requests: [partApiRequest1, partApiRequest2, partApiRequest3].filter((r) => r.apiUrl.length !== 0),
            type: state.type,
            yAxisName: state.yAxisName,
            xAxisShowNth: state.xAxisShowNth,
            decimals: state.decimals,
            doCalculateQuota: state.doCalculateQuota,
            specialType: state.specialType,
            blockId: state.blockId,
            lastPublished: state.lastPublished
        };

        await DataHelper.FetchDoubleGraphData(apiRequest, initializeGraph);
    }

    const initializeGraph = (graphData: iGraphData) => {
        if (!graphData) {
            return;
        }
        let newState = { ...state };
        let hasPrognose = newState.prognoseApiUrl.length > 0;
        if (hasPrognose) {
            DisplayHelper.DisplayPrognoseArea(graphData);
        }

        newState.textReplacements = DisplayHelper.GetTextReplacementsFromData(graphData, hasPrognose);
        newState.headTitle = DisplayHelper.ReflectDataInText(newState.headTitle, newState.textReplacements);
        graphData.title.text = null;
        graphData.chart.marginTop = 20;

        newState.graphData = graphData;
        setState(newState);
        if (newState.graphData != null) {
            EventBus.dispatch("GraphDataLoaded", newState.blockId);
        }
    }

    const addSpecificHelpText = (cmsText: string): string => {
        if (!state.graphData?.series?.length) {
            return cmsText;
        }
        let type = state.graphData.chart.type;
        let heading = getTableHeading();
        let unit = state.specialType === 1 ? '%' : state.valueSuffix;
        let result = DisplayHelper.ReflectDataInText(cmsText, state.textReplacements);
        result += DisplayHelper.ToHTMLTable(state.graphData?.series, type, state.headTitle, heading, state.decimals, unit, state.helpInvertTableAxis, state.helpColumnSortOrder);
        return result;
    };

    const getTableHeading = () => {
        return (DisplayHelper.ReflectDataInText(state.helpColumnHeaders, state.textReplacements, true)?.split(DisplayHelper.DataDelimiter) || state.graphData?.xAxis?.categories) ?? [];
    };

    return (
        <React.Fragment>
            <h3>{state.headTitle}</h3>
            <div className="graph-container">
                <div className="scroll-container">
                    {(state.graphData != null || delayed) ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={state.graphData}
                        />
                        :
                        <div>
                            <p><span className="label label-danger">Fel vid rendering av dubbel graf</span></p>
                            <p><strong>URL 1:</strong> <code>{state.apiUrlOne}</code></p>
                            <p><strong>RequestBody 1:</strong> <code>{state.requestBodyOne}</code></p>
                            <p><strong>URL 2:</strong> <code>{state.apiUrlTwo}</code></p>
                            <p><strong>RequestBody 2:</strong> <code>{state.requestBodyTwo}</code></p>
                            <p><strong>URL 3:</strong> <code>{state.prognoseApiUrl}</code></p>
                            <p><strong>RequestBody 3:</strong> <code>{state.prognoseRequestBody}</code></p>
                        </div>
                    }
                </div>
            </div>
            {(state.graphData != null || delayed) && (
                <EnergipresentationExportButton
                    series={state.graphData?.series}
                    type={state.graphData?.chart?.type}
                    title={state.headTitle}
                    heading={getTableHeading()}
                    unit={state.valueSuffix}
                    sortOrder={state.helpColumnSortOrder}
                />
            )}
            {(state.graphData != null || delayed) && state.helpText && state.helpText != "" && (
                <EnergipresentationAccordion
                    key={"accordion_" + state.blockId}
                    title={state.helpTitle}
                    text={addSpecificHelpText(state.helpText)}
                    modelStyle={state.helpModelStyle}
                />
            )}
        </React.Fragment >
    );
};
export default EnergipresentationDoubleGraph;