import iCircleData from './ResponseModels/iCircleData';
import iGraphData from './ResponseModels/iGraphData';
import iPlotBand from './ResponseModels/iPlotBand';
import iSeriesItem from './ResponseModels/iSeriesItem';
import iSankeySeriesItem from './ResponseModels/iSankeySeriesItem';
import iCircleDatapost from './ResponseModels/iCircleDatapost';
import iScaleData from './ResponseModels/iScaleData';
import iTextReplacements from './iTextReplacements';
import SortOrder from './SortOrderEnum';

export default class DisplayHelper {

    private constructor() { }
    private static get language():string { return document.documentElement.lang || navigator.language };

    public static ToHTMLTable(series: Array<iSeriesItem | iSankeySeriesItem | iCircleDatapost> | iScaleData, type: string, title: string, heading: Array<string>, decimals: number, unit: string, inverted: boolean = false, sortOrder?: SortOrder): string {
        let tableData = this.ToWorksheetData(series, type, title, heading, unit, inverted, sortOrder)
            .slice(2);// skipping title

        decimals = decimals === null ? (type !== 'pie' && unit === '%' ? 2 : type === 'sankey' ? 3 : 1) : decimals;
        const numberFormat = new Intl.NumberFormat(this.language, { maximumFractionDigits: decimals, minimumFractionDigits: decimals });
        const ttsUnit = (unit === 'TWh' ? this.LocaleText('Terawatt hours', 'Terawattimmar') :
                        unit === 'GWh' ? this.LocaleText('Gigawatt hours', 'Gigawattimmar') :
                        unit === 'MWh' ? this.LocaleText('Megawatt hours', 'Megawattimmar') :
                        unit === 'kWh' ? this.LocaleText('Kilowatt hours', 'Kilowattimmar') : unit);

        const th = 'th',
            td = 'td',
            theadTh = !(type === 'sankey' || type === 'scale') || !inverted,
            rowTh = !(type === 'sankey' || type === 'scale') || inverted,
            toTr = function (tr: Array<any>, isHeader: boolean = false): string {
                return tr.map((d, i) => {
                    let tag = (isHeader || (i === 0 && rowTh) ? th : td);
                    let data = (typeof d === 'number' ? numberFormat.format(d) : d);
                    let text = tag === td && typeof d === 'number' ? `${data} <span class="rs-message sr-only" aria-label="${ttsUnit}">${ttsUnit}</span>` : data;
                    let cssClass = typeof d === 'number' ? ' class="value"' : '';
                    return `<${tag}${cssClass}>${text}</${tag}>`;
                }).join('');
            };

        const thead = `<tr>${toTr(tableData[0], theadTh)}</tr>`;
        const tbody = tableData.slice(1).map((tr) => { return `<tr>${toTr(tr)}</tr>`; }).join('');
        const tableScrollCSS = rowTh ? ' stick' : '';

        return `<div class="body-wrapper"><div class="scroll-container"><table class="rs_content${tableScrollCSS}"><thead>${thead}</thead><tbody>${tbody}</tbody></table></div></div>`;
    };

    public static ToWorksheetData(series: Array<iSeriesItem | iSankeySeriesItem | iCircleDatapost> | iScaleData, type: string, title: string, heading: Array<string>, unit: string, inverted: boolean = false, sortOrder?: SortOrder):
        Array<Array<string | number>> {

        const workSheet = [[title + (unit ? ` (${unit})` : '')], []] as Array<Array<string | number>>;
        if (type === 'scale') {
            const scaleData = this.getScaleData(series as iScaleData, unit);
            workSheet.push(...(inverted ? this.SwapDataAxis(scaleData) : scaleData));
        }
        else {
            let localHeading = heading;
            if (type === 'sankey') {
                localHeading = [this.LocaleText('From', 'Från'), this.LocaleText('To', 'Till'), unit];
            }

            const seriesData = this.getSeriesData(series as Array<iSeriesItem | iSankeySeriesItem | iCircleDatapost>, type);
            const worksheetData = sortOrder
                ? this.orderColumns(this.getWorksheetData(seriesData, localHeading), type, sortOrder)
                : this.getWorksheetData(seriesData, localHeading);
            workSheet.push(...(inverted ? this.SwapDataAxis(worksheetData) : worksheetData));
        }

        return workSheet;
    };
    private static getScaleData(data: iScaleData, unit: string):
        Array<Array<string | number>> {

        const seriesData = [] as Array<Array<string | number>>;
        const rightSideValues = [...data.sourceCValues, ...data.sourceBValues];
        const leftSide = this.LocaleText('Energy supply', 'Tillförd energi');
        const rightSide = this.LocaleText('Energy consumption', 'Energianvändning');
        const totalText = this.LocaleText('Total', 'Totalt');
        seriesData.push([leftSide, unit, rightSide, unit]);
        seriesData.push([totalText, data.sourceAEltillforselTotal, totalText, data.sourceRightSideTotal]);
        data.sourceAValues.forEach((item, i) => seriesData.push([item.key, item.value[0], rightSideValues[i].key, rightSideValues[i].value[0]]));
        seriesData.push(['', '', data.descriptionDifference, data.statisticDifference]);
        seriesData.push(['', '', data.resultTextSourceC, data.sourceCEnergianvandningBranslenTotal]);
        seriesData.push(['', '', data.resultTextSourceB, data.sourceBEnergianvandningTotal]);

        return seriesData;
    };
    private static getSeriesData(series: Array<iSeriesItem | iSankeySeriesItem | iCircleDatapost>, type: string):
        Array<Array<string | number>> {

        const seriesData = [] as Array<Array<string | number>>;

        series.forEach(serie => {
            if (type === 'pie') {
                (serie as iCircleDatapost).data.forEach(item => {
                    seriesData.push([item.name, item.y]);
                });
            }
            else if (type === 'sankey') {
                (serie as iSankeySeriesItem).data.forEach(item => {
                    seriesData.push([item.from, item.to, item.weight]);
                });
            }
            else {// area || spline || column
                serie = serie as iSeriesItem;
                seriesData.push([serie.name, ...serie.data]);
            }
        });

        return seriesData;
    };
    private static getWorksheetData(seriesData: Array<Array<string|number>>, heading: Array<string>): Array<Array<any>> {
        const chartHeading = heading.length === (seriesData[0]?.length ?? -1) ? heading : ['', ...heading];
        const worksheetData = [];

        if (heading?.length > 0) {
            worksheetData.push(chartHeading);
        }
        worksheetData.push(...seriesData);
        return worksheetData;
    };
    private static SwapDataAxis(data: Array<Array<string | number>>): Array<Array<string | number>> {
        const transposedData = data[0].map((_, colIndex) => data.map(row => row[colIndex]));
        return transposedData;
    };
    private static orderColumns(seriesData: Array<Array<string | number>>, type: string, order: SortOrder): Array<Array<string | number>> {
        if (type === 'sankey' || type === 'scale') {
            return seriesData;
        }

        const data = type === 'pie' ? this.SwapDataAxis(seriesData) : seriesData;
        const rows = data.map(row => row.map((col, i) => { return { key: i, val: col }; }));
        const map = [0, ...this.orderArray(rows[0].slice(1), order, 'val').map(kv => kv.key)];
        const sorted = rows.map(row => row.map((_, i) => row[map[i]].val));

        if (type === 'pie') {
            return this.SwapDataAxis(sorted);
        }

        //type === 'area' || type === 'spline' || type === 'column'
        return sorted;
    };

    public static OrderByDescending(arr: Array<any>, key?: string): Array<any> {
        return this.orderArray(arr, SortOrder.Descending, key);
    };
    public static OrderBy(arr: Array<any>, key?: string): Array<any> {
        return this.orderArray(arr, SortOrder.Ascending, key);
    };
    private static orderArray(arr: Array<any>, sortOrder: SortOrder, key?: string): Array<any> {
        if (key && arr.some(x => !Object.keys(x).includes(key))) {
            throw Error(`Not all array items have the key: '${key}'`);
        }
        const collator = new Intl.Collator(this.language, { usage: 'sort' });
        return arr.sort((key
            ? (
                sortOrder === SortOrder.Descending
                    ? (a, b) => { return collator.compare(b[key], a[key]); }
                    : (a, b) => { return collator.compare(a[key], b[key]); }
            )
            : (
                sortOrder === SortOrder.Descending
                    ? (a, b) => { return collator.compare(b, a); }
                    : (a, b) => { return collator.compare(a, b); }
            )));
    };

    public static GetTextReplacementsFromData(graphData: iGraphData | iCircleData, hasPrognose: boolean = false): iTextReplacements {

        if (graphData?.chart?.type === 'pie') {
            return this.getTextReplacemntsFromCircleData(graphData as iCircleData, hasPrognose);
        }

        return this.getTextReplacemntsFromGraphData(graphData as iGraphData, hasPrognose);
    };
    private static getTextReplacemntsFromCircleData(circleData: iCircleData, hasPrognose: boolean = false): iTextReplacements {

        let allYears = circleData?.series.map(s => s.name);
        let allYearsSorted = allYears.sort();
        return {
            FROM: allYearsSorted[0] ?? null,
            TO: allYearsSorted[allYearsSorted.length - 1] ?? null,
            SERIES: circleData?.series.map(s => s.name).join(this.DataSeparator) ?? null,
            CATEGORIES: circleData?.series.map((s) => s.data.map(d => d.name).join(this.DataSeparator)).join(this.DataSeparator) ?? null,
            ...this.getTextReplacementsFromText(circleData?.title?.text || "")
        } as iTextReplacements;
    };
    private static getTextReplacemntsFromGraphData(graphData: iGraphData, hasPrognose: boolean = false): iTextReplacements {

        let categories = [...graphData?.xAxis?.categories];
        let textReplacements = {
            SERIES: graphData?.series?.map(s => s.name).join(this.DataSeparator),
            CATEGORIES: categories?.join(this.DataSeparator),
        } as iTextReplacements;

        if (hasPrognose) {
            graphData?.xAxis?.plotBands?.filter(p => this.prognoseRegExp.test(p.label?.text ?? '')).every(pb => console.log(pb.label.text));
            let prognosePlotBands = graphData?.xAxis?.plotBands?.filter(p => this.prognoseRegExp.test(p.label?.text ?? ''));
            textReplacements.START = prognosePlotBands?.length ? categories[prognosePlotBands[0]['startIndex']] : null;
            textReplacements.END = prognosePlotBands?.length ? categories[prognosePlotBands[0]['endIndex']] : null;
            let catFiltered = [...(textReplacements.START ? categories.filter(c => c < textReplacements.START) : categories)];
            textReplacements.FROM = categories?.length ? catFiltered[0] : null;
            textReplacements.TO = categories?.length ? catFiltered.reverse()[0] : null;
        }
        textReplacements.FROM = textReplacements.FROM || (categories?.length ? categories[0] : null);
        textReplacements.TO = textReplacements.TO || (categories?.length ? categories[categories.length - 1] : null);

        return {
            ...textReplacements,
            ...this.getTextReplacementsFromText(graphData?.title?.text || "")
        } as iTextReplacements;
    };
    private static getTextReplacementsFromText(text: string): iTextReplacements {

        const regexp: RegExp = /^\w+\s\d{4};\w+\s\d{4}$/;
        if (!regexp.test(text)) {
            return {};
        }
        let texts = text.split(';');
        let from = texts[0];
        let to = texts[1];
        return { FROM: from, TO: to } as iTextReplacements;
    };

    public static DataDelimiter: RegExp = /\s?\|\s?/;
    public static DataSeparator: string = '|';
    private static prognoseRegExp: RegExp = /([Ff]orecast)|([Pp]rognos)/;

    public static ReflectDataInText(text: string, data: iTextReplacements, keepDelimiters:boolean = false): string {
        if (!text) {
            return null;
        }
        if (!data || Object.keys(data).every((k) => { return !data[k]; })) {
            return text;
        }

        let tempData = { ...data } as iTextReplacements;
        let result = text;

        if (!keepDelimiters) {
            if (data?.SERIES) {
                let all = data.SERIES.split(this.DataDelimiter)
                    .map(s => s.trim())
                    .filter(s => !s.toLowerCase().includes('total'));
                let last = all[all.length - 1];
                tempData.SERIES = all.join(', ').replace(`, ${last}`, this.LocaleText(` and also ${last}`, ` samt ${last}`));
            }
            if (data?.CATEGORIES) {
                let all = data.CATEGORIES.split(this.DataDelimiter)
                    .map(s => s.trim())
                    .filter(s => !s.toLowerCase().includes('total'));;
                let last = all[all.length - 1];
                tempData.CATEGORIES = all.join(', ').replace(`, ${last}`, this.LocaleText(` and also ${last}`, ` samt ${last}`));
            }
        }

        Object.keys(tempData).forEach((key) => {
            if (!tempData[key]) { return; }
            result = result.replace(`[${key}]`, tempData[key]);
        });
        return result;
    };

    public static LocaleText(english: string, swedish: string): string {
        return this.language === 'en' ? english : swedish;
    };

    public static DisplayPrognoseArea(graphData: iGraphData) {

        if (!graphData.xAxis.plotBands) {
            return;
        }

        let prognosePlotBands = graphData?.xAxis?.plotBands?.filter(p => this.prognoseRegExp.test(p.label?.text ?? ''));
        for (let i = 0; i < prognosePlotBands.length; i++) {
            graphData.xAxis.plotBands[i]['startIndex'] = graphData.xAxis.plotBands[i].from;
            graphData.xAxis.plotBands[i]['endIndex'] = graphData.xAxis.plotBands[i].to;

            graphData.xAxis.plotBands[i].from -= 0.5;
            if (graphData.chart.type !== 'area') {
                graphData.xAxis.plotBands[i].to += 0.5;
            }

            this.addPlotBandBackground(graphData.xAxis.plotBands[i]);
            this.addPlotBandLine(graphData);

            this.setHoverEffects(graphData);
        }
    };
    private static addPlotBandBackground(plotBand: iPlotBand) {
        plotBand.color = {
            pattern: {
                path: 'M0,10 L10,0 M-1,1 L1,-1 M9,11 L11,9',
                width: 10,
                height: 10,
                color: '#666666',
                backgroundColor: '#E0E0DF',
                opacity: 1
            },
        };
    };
    private static addPlotBandLine(graphData: iGraphData) {
        graphData.chart.events = graphData.chart.events || {};
        graphData.chart.events.render = function () {
            const chart = this;
            const xAxis = chart.xAxis[0];
            const plotBand = xAxis.plotLinesAndBands[0].options;

            if (!plotBand) {
                return;
            }

            // Calculate the position for the dashed line
            const xPos = xAxis.toPixels(plotBand.from);
            const yStart = chart.plotTop - 10;
            const yEnd = chart.plotTop + chart.plotHeight + 10;

            // Draw a dashed line for the left border
            if (!graphData.chart['prognoseLine']) {
                graphData.chart['prognoseLine'] = chart.renderer.path(['M', xPos, yStart, 'L', xPos, yEnd])
                    .attr({
                        'stroke-width': 2,
                        stroke: (plotBand?.label?.style?.color ?? 'black'),
                        dashstyle: 'Dash',
                        zIndex: 6,
                        class: 'prognose-line'
                    });
                graphData.chart['prognoseLine'].add();
            }
            else {
                graphData.chart['prognoseLine'].attr({ d: ['M', xPos, yStart, 'L', xPos, yEnd] });
            }
        };
    };
    private static setHoverEffects(graphData: iGraphData) {
        if (graphData.chart.type !== 'area') {
            return;
        }
        let plotOptions = graphData.plotOptions[graphData.chart.type];
        plotOptions.states = plotOptions.states || {};
        plotOptions.states.inactive = { opacity: 1 };
        plotOptions.events = plotOptions.events || {};
        plotOptions['inactiveOtherPoints'] = false;
        plotOptions['stickyTracking'] = true;

        graphData.series.forEach((s) => {
            s['originalColor'] = s.color;
            s['brightenedColor'] = this.brighten(s.color, 0.3);
        });

        plotOptions.events.mouseOver = (e) => {
            e.target['chart'].series.forEach((s) => {
                if (s !== e.target && s.color !== s.options.brightenedColor) {
                    s.update({ color: s.options.brightenedColor });
                }
            });
        };
        plotOptions.events.mouseOut = (e) => {
            e.target['chart'].series.forEach((s) => {
                if (s !== e.target) {
                    s.update({ color: s.options.originalColor || s.color });
                }
            });
        };
    };

    private static brighten(color: string, alpha: number): string {
        var values = this.hexToRGB(color);
        for (var i = 0; i < values.length; i++) {
            values[i] = Math.round((alpha * values[i]) + (1 - alpha) * 255);
        }
        return this.rgbToHex(values);
    };
    private static hexToRGB(color: string): number[] {
        var xr = color.substring(1, 3);
        var xg = color.substring(3, 5);
        var xb = color.substring(5, 7);
        var r = parseInt(xr, 16);
        var g = parseInt(xg, 16);
        var b = parseInt(xb, 16);
        return [r, g, b];
    };
    private static rgbToHex(values: number[]): string {
        var hex = "#";
        for (var i = 0; i < values.length; i++) {
            var p1 = "0123456789ABCDEF".charAt((values[i] - values[i] % 16) / 16);
            var p2 = "0123456789ABCDEF".charAt(values[i] % 16);
            hex += (p1 + p2);
        }
        return hex;
    };
};