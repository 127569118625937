import * as React from "react";
import * as ReactDOM from "react-dom";

import MobileTopBar from './MobileTopBar';

$(document).ready(function () {
    if (document.getElementById('MobileTopBar')) {
        let mobileTopBar = document.getElementById('MobileTopBar');
        let langCode = mobileTopBar.getAttribute("data-lang-code");
        let logoLink = mobileTopBar.getAttribute("data-logo-link");
        let logoTitle = mobileTopBar.getAttribute("data-logo-title");
        let searchLink = mobileTopBar.getAttribute("data-search-link");
        let searchTitle = mobileTopBar.getAttribute("data-search-title");
        let searchBoxId = mobileTopBar.getAttribute("data-search-box-id");
        let searchLabelId = mobileTopBar.getAttribute("data-search-label-id");
        let searchTerm = mobileTopBar.getAttribute("data-search-term");
        let searchTermForm = mobileTopBar.getAttribute("data-search-term-form");
        let searchProxyUrl = mobileTopBar.getAttribute("data-search-proxy-url");
        let searchTags = mobileTopBar.getAttribute("data-search-tags");

        ReactDOM.render(
            <MobileTopBar
                langCode={langCode}
                logoLink={logoLink}
                logoTitle={logoTitle}
                searchLink={searchLink}
                searchTitle={searchTitle}
                searchBoxId={searchBoxId}
                searchLabelId={searchLabelId}
                searchTerm={searchTerm}
                searchTermForm={searchTermForm}
                searchProxyUrl={searchProxyUrl}
                searchTags={searchTags}
            />, document.getElementById("MobileTopBar"));
    }
});