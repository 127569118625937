import * as React from 'react';
import { useState, useEffect } from 'react';
import { iMobileMenuItem } from './iMobileMenu';

const initialValues = {
    isLoading: false,
    currentItem: {} as iMobileMenuItem,
    currentDepth: 0
}

interface iMobileMenuItemProps {
    currentItem: iMobileMenuItem,
    currentPageId: number
    updateActiveChain: (event: MouseEvent, currentPageId: number) => void
}

const MobileMenuItem = (props: iMobileMenuItemProps) => {
    const [state, setState] = useState(initialValues);

    useEffect(() => {
        let newState = { ...initialValues };
        newState.currentItem = props.currentItem;
        newState.isLoading = false;

        if (newState.currentItem.Id === props.currentPageId) {
            newState.currentItem.IsActive = true;
            props.updateActiveChain(null, props.currentPageId);
        }

        setState(newState);
    }, [props.currentItem]);

    const getIdForSubmenu = (item: iMobileMenuItem) => {
        return "";
    }

    const getClassesForLinkListItem = (item: iMobileMenuItem) => {
        let classes = "forward _" + item.MenuDepth;

        if (item.IsInActiveChain && !item.IsActive) {
            classes += " active-chain";
        }

        if (item.IsInActiveChain || item.IsVisible) {
            classes += " mobileVisible";
        }

        if (item.IsActive) {
            classes += " active";
        }

        if (item.IsQuickLink) {
            classes += " quicklink";
        }

        if (item.HaveChildren) {
            classes += " collapseable"
        }
       
        return classes
    }

    const getClassesForLinkAnchorItem = (item: iMobileMenuItem) => {
        let classes = "";

        if (item.IsInActiveChain) {
            classes += " active-chain";
        }

        if (item.HaveChildren) {
            classes += " collapseable"
        }

        return classes;
    }

    const toggleVisible = (event: MouseEvent, item: iMobileMenuItem) => {
        let newState = { ...state };
        let currentChild = newState.currentItem.Children.find((obj: iMobileMenuItem) => {
            return obj.Id === item.Id;
        });
        currentChild.IsVisible = !currentChild.IsVisible;

        setState(newState);
    }

    const updateActiveChain = (event: MouseEvent, currentPageId: number) => {
        let newState = { ...state };
        newState.currentItem.IsVisible = true;
        newState.currentItem.IsInActiveChain = true;
        setState(newState);

        props.updateActiveChain(event, currentPageId);
    }

    return (
        <React.Fragment>
            {state.currentItem.HaveChildren && (
                <ul className={ state.currentItem.IsVisible ? "visible" : "" } id={getIdForSubmenu(state.currentItem)}>
                    {
                        state.currentItem.Children.map((childItem, childItemIndex) => {
                            return (
                                <li key={childItemIndex} className={getClassesForLinkListItem(childItem)} data-depth={childItem.MenuDepth}>
                                    <a href={childItem.Url} className={getClassesForLinkAnchorItem(childItem)}>
                                        {childItem.Name}
                                    </a>
                                    {childItem.HaveChildren && (
                                        <span className={ childItem.IsVisible ? "arrow open" : "arrow closed" } onClick={(event) => toggleVisible(event.nativeEvent, childItem)}></span>
                                    )}

                                    <MobileMenuItem currentItem={childItem} currentPageId={props.currentPageId} key={childItemIndex} updateActiveChain={updateActiveChain} />
                                </li>
                            );
                        })
                    }
                </ul>
            )}
        </React.Fragment>
    );
};
export default MobileMenuItem;
