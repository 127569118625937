import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';

patternFill(Highcharts);

interface SeriesData {
    name: string;
    data: number[];
    color?: string;
}

interface KalkylElefterfraganGraphProps {
    series: SeriesData[];
    unit: string;
}

const KalkylElefterfraganGraph: React.FC<KalkylElefterfraganGraphProps> = ({ series, unit }) => {
    const colors = [
        "#BBB5AB", // Lera alt 3
        "#C1DEBA", // Grönska alt 1
        "#C1DEBA", // Grönska alt 1
        "#7F1F10", // Tegel alt 4
        "#F6AA72", // Tegel alt 3
        "#006875", // Hav
        "#EF7B44", // Tegel alt 2
    ];

    const seriesWithPatterns = series.map((item, seriesIndex) => ({
        ...item,
        data: item.data.map((point, categoryIndex) => {
            if (seriesIndex === 1) { 
                return {
                    y: point,
                    color: {
                        pattern: {
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                strokeWidth: 2,
                                backgroundColor: "#C1DEBA",
                                stroke: "#50B26C",
                            },
                            width: 8,
                            height: 8,
                            backgroundColor: "#C1DEBA",
                            color: item.color || colors[seriesIndex % colors.length],
                        }
                    },
                };
            }
            return { y: point, color: item.color || colors[seriesIndex % colors.length] };
        }),
        color: item.color || colors[seriesIndex % colors.length],
        showInLegend: item.name === 'Industri, befintlig spann övre' ? false : true,
    }));

    const options = {
        chart: {
            type: 'column',
            height: 500,
            events: {
                render: function () {
                    const chart = this;

                    chart.legend.allItems.forEach((series) => {
                        const legendGroup = series.legendItem.group?.element;

                        if (legendGroup) {
                            legendGroup.addEventListener('mouseenter', () => {
                                series.setState('hover');
                                if (series.name != 'Industri, befintlig') {
                                    chart.series.find(s => s.name === 'Industri, befintlig spann övre').setState('inactive');
                                } else {
                                    chart.series.find(s => s.name === 'Industri, befintlig spann övre').setState('hover');
                                }
                            });

                            legendGroup.addEventListener('mouseleave', () => {
                                series.setState('normal');
                                chart.series.find(s => s.name === 'Industri, befintlig spann övre').setState('normal');
                            });
                        }
                    });
                }
            }

        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontSize: "1.7em",
            },
        },
        xAxis: {
            labels: {
                style: {
                    fontSize: "1.7em",
                },
                step: 1,
            },
            categories: ['Elproduktion i länet, 2022', 'Efterfrågan på el, 2022', 'Efterfrågan på el, 2030', 'Efterfrågan på el, 2045']
        },
        yAxis: {
            min: 0,
            title: {
                text: unit,
                style: {
                    fontSize: "1.7em",
                }
            },
            labels: {
                format: '{value}',
                style: {
                    fontSize: "1.7em",
                }
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontSize: "1.7em",
                }
            }
        },
        legend: {
            align: 'center',
            x: 0,
            verticalAlign: 'bottom',
            y: 0,
            floating: false,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 0,
            shadow: false,
            itemStyle: {
                fontSize: "1.7em",
            }
        },
        tooltip: {
            style: {
                fontSize: "1.7rem",
            },
            formatter: function () {
                const seriesNamesToCombine = ['Industri, befintlig', 'Industri, befintlig spann övre'];
                const currentSeriesIndex = this.point.index; 
                const chart = this.series.chart;

                if (seriesNamesToCombine.includes(this.series.name)) {
                    const value = chart.series.find(s => s.name === 'Industri, befintlig')?.data[currentSeriesIndex]?.y || 0;
                    const valueUpperRange = chart.series.find(s => s.name === 'Industri, befintlig spann övre')?.data[currentSeriesIndex]?.y || 0;
                    const combinedValue = value + valueUpperRange;

                    chart.series.find(s => s.name === 'Industri, befintlig spann övre').setState('hover');
                    chart.series.find(s => s.name === 'Industri, befintlig').setState('hover');

                    return `<span style="color:${this.color}">\u25CF</span> Industri, befintlig: <b>${value}${combinedValue > value ? `- ${combinedValue}` : ''}</b> ${unit}`;
                } else {
                    return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${parseFloat(this.y).toFixed(2)}</b> ${unit}`;
                }
            },
            shared: false
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                borderColor: '#332E24',
                borderWidth: 1,
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        enabled: true,
                    }
                }
            },
            series: {
                events: {
                    legendItemClick: function () {
                        const chart = this.chart;
                        const upperRange = chart.series.find(s => s.name === 'Industri, befintlig spann övre');
                        if (this.name === 'Industri, befintlig') {
                            if (this.visible) {
                                upperRange?.hide();
                            } else {
                                upperRange?.show();
                            }
                        }
                    },
                }
            }
        },
        series: seriesWithPatterns
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default KalkylElefterfraganGraph;
