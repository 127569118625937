import * as React from "react";
import * as ReactDOM from "react-dom";
import KalkylElefterfragan from './KalkylElefterfragan';

$(document).ready(function () {
    if (document.getElementsByClassName('kalkyl-elefterfragan-block-react-container')) {
        let kalkylElefterfragan = document.getElementsByClassName('kalkyl-elefterfragan-block-react-container');

        for (let i = 0; i < kalkylElefterfragan.length; i++) {
            let currentBlock = kalkylElefterfragan[i];

            let mainText = currentBlock.getAttribute("data-main-text");
            let helptextIndustryExisting = currentBlock.getAttribute("data-helptext-industry-existing");
            let helptextIndustryUpcoming = currentBlock.getAttribute("data-helptext-industry-upcoming");
            let helptextTransportLight = currentBlock.getAttribute("data-helptext-transport-light");
            let helptextTransportHeavy = currentBlock.getAttribute("data-helptext-transport-heavy");
            let helptextResidences = currentBlock.getAttribute("data-helptext-residences");
            let helptextService = currentBlock.getAttribute("data-helptext-service");

            let currentLanguage = currentBlock.getAttribute("data-current-language");

            let buttonCalculate = currentBlock.getAttribute("data-button-calculate");
            let buttonReset = currentBlock.getAttribute("data-button-reset");

            let sumText = currentBlock.getAttribute("data-sum-text");

            let blockReference = getAsIntOrZero(currentBlock.getAttribute("data-block-reference"));

            let productionApiUrl = currentBlock.getAttribute("data-api-url");
            let productionApiRequest = currentBlock.getAttribute("data-api-request");

            fetchApiData('/api/ElectricityDemandApi/GetCounties', { id: blockReference, culture: currentLanguage })
                .then(apiData => {

                    ReactDOM.render(<KalkylElefterfragan
                        mainText={decodeHtmlEntities(mainText)}

                        blockReference={blockReference}
                        currentLanguage={currentLanguage}
                        helptextIndustryExisting={helptextIndustryExisting}
                        helptextIndustryUpcoming={helptextIndustryUpcoming}
                        helptextTransportLight={helptextTransportLight}
                        helptextTransportHeavy={helptextTransportHeavy}
                        helptextResidences={helptextResidences}
                        helptextService={helptextService}

                        productionApiUrl={productionApiUrl}
                        productionApiRequest={productionApiRequest}

                        buttonCalculate={decodeHtmlEntities(buttonCalculate)}
                        buttonReset={decodeHtmlEntities(buttonReset)}
                        sumText={sumText}
                        countyDefaultValue={0}
                        counties={apiData}

                        installedEffect={""}
                        installedEffectHelpText={""}
                        installedEffectUnit={""}
                        installedEffectDefaultValue={0}

                        fetchApiData={fetchApiData}

                        key={"kalkylElefterfragan_" + i}
                    />, currentBlock);
                });
        }
    }
});

function getAsIntOrZero(valueString: string): number {
    let parsed = parseInt(valueString);
    return isNaN(parsed) ? 0 : parsed;
}

function decodeHtmlEntities(encodedString: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString(`<div>${encodedString}</div>`, 'text/html');
    return dom.body.textContent || "";
}

async function fetchApiData(url: string, requestData: object) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return null;
    }
}
