import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { getFullMenu, getSearchSuggestions } from './DataHelper';
import iMobileMenu, { iMobileMenuItem } from './iMobileMenu';
import { iMobileTopBar } from './iMobileTopBar';
import { iMobileTopBarProps } from './iMobileTopBarProps';
import MobileMenuItem from './MobileMenuItem';
import iAutocompleteResponse, { iAutocompleteHit } from './iAutocompleteResponse';


const initialValues: iMobileTopBar = {
    isMenuVisible: false,
    isSearchVisible: false,
    menu: {
        CurrentPageId: -1,
        //Items: [] as Array<iMobileMenuItem>,
        Menu: {} as iMobileMenuItem,
        CurrentDepth: 0,
        menuLeftStyle: {
            left: "0%"
        }
    },
    menuIsLoading: false,
    showSearchSuggestions: false,
    searchSuggestions: null,
    searchTerm: ""
}

function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null);

    const setStateCallback = useCallback((state, callback) => {
        cbRef.current = callback;
        setState(state);
    }, []);

    useEffect(() => {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
        }
    }, [state]);

    return [state, setStateCallback];
}

const MobileTopBar = (props: iMobileTopBarProps) => {
    const [state, setState] = useStateCallback(initialValues);
    const ref = useRef(null);
    const searchBoxRef = useRef(null);

    useEffect(() => {
        const handleClickOutsideSuggestion = (event) => {
            if (state.isSearchVisible && state.showSearchSuggestions) {
                let targetIsChild = false;

                if (event.target.id !== undefined && event.target.id !== "" && ref.current) {
                    let targetElement = document.getElementById(event.target.id);

                    if (!targetElement) {
                        return;
                    }

                    let closestReference = targetElement.closest("#" + ref.current.id);
                    targetIsChild = closestReference !== undefined && closestReference !== null;
                }

                if (!targetIsChild) {
                    let newState: iMobileTopBar = { ...state };
                    newState.showSearchSuggestions = false;
                    setState(newState);
                }
            }
        }

        document.addEventListener("click", handleClickOutsideSuggestion);

        return () => {
            document.removeEventListener("click", handleClickOutsideSuggestion);
        };
    }, [ref, state])

    const showMenu = () => {
        let newState = { ...state };
        newState.isMenuVisible = true;
        newState.isSearchVisible = false;
        loadMenu(newState);
        document.body.classList.add("mobile-menu-open");
    }

    const hideMenu = () => {
        let newState = { ...state };
        newState.isMenuVisible = false;
        setState(newState);
        document.body.classList.remove("mobile-menu-open");
    }

    const showSearch = () => {
        let newState: iMobileTopBar = { ...state };
        newState.isSearchVisible = true;
        newState.isMenuVisible = false;
        setState(newState);

        setTimeout(() => {
            searchBoxRef.current.focus();
        }, 150);
    }

    const hideSearch = () => {
        let newState = { ...state };
        newState.isSearchVisible = false;
        setState(newState);
    }

    const loadMenu = (newState) => {
        newState.menuIsLoading = true;
        setState(newState, (updatedState) => fetchData(updatedState));
    }

    const fetchData = (updatedState) => {
        getFullMenu((data: iMobileMenu) => {
            let newState = { ...updatedState };

            if (typeof data !== "undefined" && data !== null) {
                newState.menu.CurrentPageId = data.CurrentPageId;
                newState.menu.Menu = data.Menu;
                newState = setCurrentMenuDepth(newState, data.CurrentDepth);
            }

            newState.menuIsLoading = false;
            setState(newState);
        });
    };

    const setCurrentMenuDepth = (newState: iMobileTopBar, newDepth: number) => {
        newState.menu.CurrentDepth = newDepth;
        newState.menu.menuLeftStyle = {
            ...newState.menu.menuLeftStyle,
            left: (-(newDepth) * 100).toString() + "%"
        };

        return newState;
    }

    const updateActiveChain = (event: MouseEvent, currentPageId: number) => {
    }

    const showSuggestions = (event) => {
        let searchTerm = event.target.value;
        setState((prevState) => {
            return {
                ...prevState,
                searchTerm: searchTerm
            };
        });

        getSearchSuggestions(searchTerm, props.searchProxyUrl, props.searchTags, (data: iAutocompleteResponse) => {
            if (data && data.hits.length > 0) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        searchSuggestions: data.hits,
                        showSearchSuggestions: true
                    };
                });
            } else {
                setState((prevState) => {
                    return {
                        ...prevState,
                        searchSuggestions: null,
                        showSearchSuggestions: false
                    };
                });
            }
        });
    }

    const changeSearchTerm = (newSearchTerm: string) => {
        let newState = { ...state };

        newState.showSearchSuggestions = false;
        newState.searchTerm = newSearchTerm;

        setState(newState);
    }

    return (
        <React.Fragment>
            <div className="logo-container">
                <a className="SiteLogo" href={props.logoLink} title={props.logoTitle}>
                    {
                        props.langCode === "en" && (
                            <img src="/Static/img/logo_white_en.svg" alt="Energimyndigheten" />
                        )
                    }
                    {
                        props.langCode !== "en" && (
                            <img src="/Static/img/logo_white.svg" alt="Energimyndigheten" />
                        )
                    }

                </a>
            </div>
            <div className="buttons-container">
                <div className="search-button" onClick={() => { state.isSearchVisible ? hideSearch() : showSearch() }}>
                    <div className="search-icon">
                    </div>
                    <div className="search-text">
                        Sök
                    </div>
                </div>
                <div className="menu-button" onClick={() => { state.isMenuVisible ? hideMenu() : showMenu() }}>
                    <div className={state.isMenuVisible ? "menu-icon open" : "menu-icon"}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="menu-text">
                        Meny
                    </div>
                </div>
            </div>

            <div id="MobileSearch" className={state.isSearchVisible ? "open" : "closed"}>
                <div className="search-container">
                    <div className="mobile-searchForm" ref={ref} id="MobileSearchForm">
                        <form method="get" action={props.searchLink} title={props.searchTitle}>
                            <input type="text" id={props.searchBoxId} value={state.searchTerm || ""} placeholder={props.searchTerm} className="search-searchbox" onChange={showSuggestions} name="query" ref={searchBoxRef} />
                        </form>
                        {state.showSearchSuggestions && (
                            <div className="search-suggestions" id="SearchSuggestionListContainer">
                                <ul id="SearchSuggestionList">
                                    {state.searchSuggestions.map((suggestionItem: iAutocompleteHit, suggestionIndex: number) => {
                                        return (
                                            <li
                                                key={suggestionIndex}
                                                id={("SuggestionItem_" + suggestionIndex)}
                                                className="search-suggestion-item"
                                                onClick={() => changeSearchTerm(suggestionItem.query)}>
                                                {suggestionItem.query}
                                            </li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <nav id="MobileMenu" className={state.isMenuVisible ? "open" : "closed"}>
                {state.menuIsLoading && (
                    <div id="loader-container">
                        <div id="loader-spinner"></div>
                    </div>
                )}
                {(state.isMenuVisible && !state.menuIsLoading) && (
                    <MobileMenuItem currentItem={state.menu.Menu} currentPageId={state.menu.CurrentPageId} updateActiveChain={updateActiveChain} />
                )}
            </nav>
        </React.Fragment>
    );
};
export default MobileTopBar;
