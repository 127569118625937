import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import iKalkylUrsprungsgarantier from './iKalkylUrsprungsgarantier';
import iKalkylUrsprungsgarantierProps from './iKalkylUrsprungsgarantierProps';
import HelpText from './HelpText';

const KalkylUrsprungsgarantier = (props: iKalkylUrsprungsgarantierProps) => {
    let initialValues: iKalkylUrsprungsgarantier = {
        props: props,
        showResult: false,
        isCleared: true,
        isCalculated: false,
        isValid: false,
        annualFee: props.annualFeeDefaultValue,
        estimatedAnnualProductionMin: 1,
        estimatedAnnualProductionMax: 999,
        estimatedAnnualProductionIsValid: false,
        estimatedAnnualProductionIsFocused: false,
        installedEffectMin: 1,
        installedEffectMax: 999,
        installedEffectIsValid: false,
        installedEffectIsFocused: false,
        addedCompensationMin: 0,
        addedCompensationMax: 25,
        addedCompensationIsValid: false,
        addedCompensationIsFocused: false,
        compensationUGMin: 0,
        compensationUGMax: 250,
        compensationUGIsValid: false,
        compensationUGIsFocused: false,
        percentageSolarMin: 0,
        percentageSolarMax: 100,
        percentageSolarIsValid: false,
        percentageSolarIsFocused: false
    };

    const inputRefs: { [key: string]: React.RefObject<HTMLInputElement> } = {
        estimatedAnnualProductionTextbox: useRef(null),
        installedEffectTextbox: useRef(null),
        addedCompensationTextbox: useRef(null),
        compensationUGTextbox: useRef(null),
        percentageSolarTextbox: useRef(null)
    }

    const [state, setState] = useState(initialValues);
    const [focusedTextbox, setFocusedTextbox] = useState<string | null>(null);

    useEffect(() => {
    }, []);

    const addThousandSeparators = (num: number): string => {
        const parts = num.toFixed(2).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        return parts.join(',');
    };

    const handleChange = (event) => {
        let valueString = event.target.value;
        let value = parseInt(valueString);
        let propertyName = event.target.name;

        if (isNaN(value)) {
            setState({
                ...state,
                [propertyName + "IsValid"]: false,
                [propertyName]: valueString,
                isCleared: false,
                isCalculated: false
            });

            return;
        }

        let isValid = validateField(propertyName, value);

        setState({
            ...state,
            [propertyName + "IsValid"]: isValid,
            [propertyName]: value,
            isCleared: false,
            isCalculated: false,
            resultValue: undefined
        });

    }

    const getText = (text) => {
        if (text === null || text === undefined || text === "") {
            return ""
        }

        return text;
    }

    const calculateUG = () => {
        if (!allIsFilled() || !validateAll()) {
            let newState = { ...state };
            newState.showResult = true;
            newState.resultValue = undefined;
            setState(newState);
            return;
        }

        let question1 = state.isQuestion1Alternative1 ? state.estimatedAnnualProduction : state.installedEffect * 0.9;
        let question2 = state.isQuestion2Alternative1 ? state.addedCompensation * 10 : state.compensationUG;

        let result = ((100 - state.percentageSolar) / 100) * question1 * question2 - state.annualFee;

        let newState = { ...state };
        newState.resultValue = result;
        newState.showResult = true;
        newState.isCalculated = true;
        setState(newState);
    }

    const allIsFilled = () => {
        if (state.isQuestion1Alternative1 === undefined || state.isQuestion1Alternative1 === null) {
            return false;
        }

        if (state.isQuestion1Alternative1 && (!state.estimatedAnnualProduction || state.estimatedAnnualProduction === undefined || state.estimatedAnnualProduction === null || isNaN(state.estimatedAnnualProduction))) {
            return false;
        }

        if (!state.isQuestion1Alternative1 && (!state.installedEffect || state.installedEffect === undefined || state.installedEffect === null || isNaN(state.installedEffect))) {
            return false;
        }

        if (state.isQuestion2Alternative1 === undefined || state.isQuestion2Alternative1 === null) {
            return false;
        }
        if (state.isQuestion2Alternative1 && (!state.addedCompensation || state.addedCompensation === undefined || state.addedCompensation === null || isNaN(state.addedCompensation))) {
            return false;
        }

        if (!state.isQuestion2Alternative1 && (!state.compensationUG || state.compensationUG === undefined || state.compensationUG === null || isNaN(state.compensationUG))) {
            return false;
        }

        if (state.annualFee === undefined || state.annualFee === null) {
            return false;
        }

        if ((!state.percentageSolar && state.percentageSolar !== 0) || state.percentageSolar === undefined || state.percentageSolar === null) {
            return false;
        }

        return true;
    }

    const validateField = (fieldName: string, value: number) => {
        let minValue: number = state[fieldName + "Min"];
        let maxValue: number = state[fieldName + "Max"];
        let isValid = value >= minValue && value <= maxValue;

        return isValid;
    }

    const validateSingleField = (fieldName: string, doValidate: boolean = true, value: number = -1) => {
        if (!doValidate) {
            return true;
        }

        let minValue: number = state[fieldName + "Min"];
        let maxValue: number = state[fieldName + "Max"];
        let currentValue: number = state[fieldName];
        let isValid = currentValue >= minValue && currentValue <= maxValue;

        return isValid;
    }

    const validateAll = () => {
        let isValid = true;

        if (state.isQuestion1Alternative1 === true && !validateSingleField("estimatedAnnualProduction", true)) {
            isValid = false;
        }

        if (state.isQuestion1Alternative1 === false && !validateSingleField("installedEffect", true)) {
            isValid = false;
        }

        if (state.isQuestion2Alternative1 === true && !validateSingleField("addedCompensation", true)) {
            isValid = false;
        }

        if (state.isQuestion2Alternative1 === false && !validateSingleField("compensationUG", true)) {
            isValid = false;
        }

        if (!validateSingleField("percentageSolar", true)) {
            isValid = false;
        }

        return isValid;
    }

    const clearAll = () => {
        setState(initialValues);
    }

    const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newState = { ...state };

        if (event.target.name === "electricityProduction") {
            if (event.target.value === "1") {
                newState.isQuestion1Alternative1 = true;
            } else if (event.target.value === "2") {
                newState.isQuestion1Alternative1 = false;
            }
        }
        else if (event.target.name === "compensation") {
            if (event.target.value === "1") {
                newState.isQuestion2Alternative1 = true;
            } else if (event.target.value === "2") {
                newState.isQuestion2Alternative1 = false;
            }
        }

        newState.isCalculated = false;
        newState.resultValue = undefined;
        newState.isCleared = false;
        setState(newState);
    }

    const getOptionsClasses = (compareTo: boolean, inputValue?: boolean) => {
        let classes = "row options-container";

        if (inputValue === null || inputValue === undefined) {
            classes += " no-choice-made";
        }

        if (compareTo === inputValue) {
            classes += " is-disabled"
        }

        return classes;
    }

    const getResultClasses = () => {
        let classes = "result-value";

        if (state.resultValue === undefined || state.resultValue === null) {
            return classes;
        }

        if (state.resultValue > 0) {
            classes += " positive";
        } else if (state.resultValue < 0) {
            classes += " negative";
        } else {
            classes += " zero";
        }

        return classes;
    }

    const getValue = () => {
        if (state.resultValue === undefined || state.resultValue === null) {
            return "-";
        }

        if (state.resultValue > 0) {
            return "+" + addThousandSeparators(state.resultValue);
        }

        return addThousandSeparators(state.resultValue);
    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.select();
        let fieldName = event.target.name;
        let newState = { ...state };
        newState[fieldName + "IsFocused"] = true;
        setState(newState);
        setFocusedTextbox(fieldName);
    }

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        let fieldName = event.target.name;
        let newState = { ...state };
        newState[fieldName + "IsFocused"] = false;
        setState(newState);
        setFocusedTextbox(null);
    }

    const getInputContainerClasses = (isValid: boolean, isFocused: boolean) => {
        let classes = "textbox-with-suffix-container";

        if (!isValid && state.showResult) {
            classes += " not-valid";
        }

        if (isFocused) {
            classes += " is-focused";
        }

        return classes;
    }
    const handleContainerClick = (textboxName: string) => {
        if (textboxName && textboxName !== focusedTextbox && inputRefs[textboxName + "Textbox"].current) {
            inputRefs[textboxName + "Textbox"].current.focus();
            inputRefs[textboxName + "Textbox"].current.select();
            setFocusedTextbox(textboxName);
        }
    }

    return (
        <div className="col-md-12">
            {/*Produktion*/}
            <div className="row">
                <div className="col-md-12 question-container">
                    <fieldset>
                        <div className="row">
                            <div className="col-md-11 col-md-offset-1">
                                <h3><legend>{getText(state.props.electricityProduction)}</legend></h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-11 col-md-offset-1 choose-instructions">
                                <span>{getText(state.props.chooseAnswerType)}</span>
                            </div>
                        </div>
                        <div className={getOptionsClasses(false, state.isQuestion1Alternative1)}>
                            <div className="col-md-offset-1 col-sm-1 col-xs-2">
                                <input id={"q1a1"} type="radio" className={state.isQuestion1Alternative1 === undefined && state.showResult ? "not-valid" : ""} name="electricityProduction" value={1} checked={state.isQuestion1Alternative1 === true} onChange={handleQuestionChange} />
                            </div>
                            <div className="col-md-5 col-sm-11 col-xs-10">
                                <label htmlFor={"q1a1"}>
                                    {state.props.estimatedAnnualProduction}
                                </label>
                                <HelpText helpText={state.props.estimatedAnnualProductionHelpText} />
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <div onClick={() => handleContainerClick("estimatedAnnualProduction")} className={getInputContainerClasses(!(state.isQuestion1Alternative1 === true && !state.estimatedAnnualProductionIsValid), state.estimatedAnnualProductionIsFocused)} >
                                    <input alt={state.props.estimatedAnnualProduction} type="number" value={state.estimatedAnnualProduction ?? ""} placeholder={state.props.estimatedAnnualProductionDefaultValue.toString()} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} ref={inputRefs["estimatedAnnualProductionTextbox"]} name="estimatedAnnualProduction" disabled={state.isQuestion1Alternative1 !== true} /><span className="suffix">{getText(state.props.estimatedAnnualProductionUnit)}</span>
                                </div>
                            </div>
                        </div>
                        <div className={getOptionsClasses(true, state.isQuestion1Alternative1)}>
                            <div className="col-md-offset-1 col-sm-1 col-xs-2">
                                <input id={"q1a2"} type="radio" className={state.isQuestion1Alternative1 === undefined && state.showResult ? "not-valid" : ""} name="electricityProduction" value={2} checked={state.isQuestion1Alternative1 === false} onChange={handleQuestionChange} />
                            </div>
                            <div className="col-md-5 col-sm-11 col-xs-10">
                                <label htmlFor={"q1a2"}>
                                    {state.props.installedEffect}
                                </label>
                                <HelpText helpText={state.props.installedEffectHelpText} />
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <div onClick={() => handleContainerClick("installedEffect")} className={getInputContainerClasses(!(state.isQuestion1Alternative1 === false && !state.installedEffectIsValid), state.installedEffectIsFocused)}>
                                    <input alt={state.props.installedEffect} type="number" value={state.installedEffect ?? ""} placeholder={state.props.installedEffectDefaultValue.toString()} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} ref={inputRefs["installedEffectTextbox"]} name="installedEffect" disabled={state.isQuestion1Alternative1 !== false} /><span className="suffix">{getText(state.props.installedEffectUnit)}</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>

            {/*Egenanvändning/Solel*/}
            <div className="row">
                <div className="col-md-12 question-container">
                    <div className="row">
                        <div className="col-md-11  col-md-offset-1">
                            <h3>{getText(state.props.percentageSolar)}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6  col-md-offset-1">
                            {getText(state.props.ownUsage)}
                            <HelpText helpText={state.props.ownUsageHelptText} />
                        </div>
                        <div className="col-md-4">
                            <div onClick={() => handleContainerClick("percentageSolar")} className={getInputContainerClasses(!(((!state.percentageSolar && state.percentageSolar !== 0) || state.percentageSolar === undefined) || !state.percentageSolarIsValid), state.percentageSolarIsFocused)}>
                                <input alt={state.props.ownUsage} type="number" value={state.percentageSolar ?? ""} placeholder={state.props.ownUsageDefaultValue.toString()} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} ref={inputRefs["percentageSolarTextbox"]} name="percentageSolar" /><span className="suffix">{getText(state.props.ownUsageUnit)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Ersättning*/}
            <div className="row">
                <div className="col-md-12 question-container">
                    <fieldset>
                        <div className="row">
                            <div className="col-md-11 col-md-offset-1">
                                <h3>
                                    <legend>
                                        {getText(state.props.compensation)}
                                        <HelpText helpText={state.props.compensationHelpText} />
                                    </legend>
                                </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-11 col-md-offset-1 choose-instructions">
                                <span>{getText(state.props.chooseAnswerType)}</span>
                            </div>
                        </div>
                        <div className={getOptionsClasses(false, state.isQuestion2Alternative1)}>
                            <div className="col-md-offset-1 col-sm-1 col-xs-2">
                                <input id={"q2a1"} type="radio" className={state.isQuestion2Alternative1 === undefined && state.showResult ? "not-valid" : ""} name="compensation" value={1} checked={state.isQuestion2Alternative1 === true} onChange={handleQuestionChange} />
                            </div>
                            <div className="col-md-5 col-sm-11 col-xs-10">
                                <label htmlFor={"q2a1"}>
                                    {state.props.addedCompensation}
                                </label>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <div onClick={() => handleContainerClick("addedCompensation")} className={getInputContainerClasses(!(state.isQuestion2Alternative1 === true && !state.addedCompensationIsValid), state.addedCompensationIsFocused)}>
                                    <input alt={state.props.addedCompensation} type="number" value={state.addedCompensation ?? ""} placeholder={state.props.addedCompensationDefaultValue.toString()} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} ref={inputRefs["addedCompensationTextbox"]} name="addedCompensation" disabled={state.isQuestion2Alternative1 !== true} /><span className="suffix">{getText(state.props.addedCompensationUnit)}</span>
                                </div>
                            </div>
                        </div>
                        <div className={getOptionsClasses(true, state.isQuestion2Alternative1)}>
                            <div className="col-md-offset-1 col-sm-1 col-xs-2">
                                <input id={"q2a2"} type="radio" className={state.isQuestion2Alternative1 === undefined && state.showResult ? "not-valid" : ""} name="compensation" value={2} checked={state.isQuestion2Alternative1 === false} onChange={handleQuestionChange} />
                            </div>
                            <div className="col-md-5 col-sm-11 col-xs-10">
                                <label htmlFor={"q2a2"}>
                                    {state.props.compensationUG}
                                </label>
                            </div>
                            <div className="col-md-4 col-xs-12">
                                <div onClick={() => handleContainerClick("compensationUG")} className={getInputContainerClasses(!(state.isQuestion2Alternative1 === false && !state.compensationUGIsValid), state.compensationUGIsFocused)}>
                                    <input alt={state.props.compensationUG} type="number" value={state.compensationUG ?? ""} placeholder={state.props.compensationUGDefaultValue.toString()} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} ref={inputRefs["compensationUGTextbox"]} name="compensationUG" disabled={state.isQuestion2Alternative1 !== false} /><span className="suffix">{getText(state.props.compensationUGUnit)}</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>

            {/*Årsavgift/Fast avgift*/}
            <div className="row">
                <div className="col-md-12 question-container">
                    <div className="row">
                        <div className="col-md-11  col-md-offset-1">
                            <h3>{getText(state.props.annualFee)}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-md-offset-1" dangerouslySetInnerHTML={{ __html: getText(state.props.annualFeeDescription) }}>
                        </div>
                        <div className="col-md-4">
                            <div className="textbox-with-suffix-container annual-fee">
                                <input alt={state.props.annualFee} type="number" value={state.annualFee} disabled={true} name="annualFee" /><span className="suffix">{getText(state.props.annualFeeUnit)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           

            <div className="row result-container">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-xs-6 result-text">
                            {state.props.resultText}
                        </div>
                        <div className="col-xs-6 result-value-container">
                            <span className={getResultClasses()}>{getValue()} </span>
                            <span className="result-unit">{state.props.resultUnit}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12  buttons-container">
                            <div className="row">
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-calculate" title={getText(state.props.buttonCalculate)} onClick={calculateUG}>{getText(state.props.buttonCalculate)}</button>
                                </div>
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-reset" title={getText(state.props.buttonReset)} onClick={clearAll}>{getText(state.props.buttonReset)}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};
export default KalkylUrsprungsgarantier;
