import * as React from "react";
import * as ReactDOM from "react-dom";
import EnergipresentationBubbles from './EnergipresentationBubbles';
import EnergipresentationDoubleBubbles from './EnergipresentationDoubleBubbles';
import EnergipresentationFilter from './EnergipresentationFilter';
import EnergipresentationGraph from './EnergipresentationGraph';
import EnergipresentationDoubleGraph from './EnergipresentationDoubleGraph';
import EnergipresentationCircle from './EnergipresentationCircle';
import EnergipresentationSankey from './EnergipresentationSankey';
import EnergipresentationScales from './EnergipresentationScales';
import SortOrder from "./SortOrderEnum";

$(document).ready(function () {

    const asIntOrDefault = (value: string, defaultValue?: number | undefined | null): number => {
        let val = parseInt(value);

        if (isNaN(val)) {
            val = defaultValue ?? (defaultValue === undefined ? 0 : null);
        }
        return val;
    };

    const getAccordionFields = (currentBlock: Element):
        { Title: string, Text: string, ColumnHeaders: string, InvertTableAxis: boolean, ColumnSortOrder: SortOrder, ModelStyle: string } => {

        let helpTitle = currentBlock.getAttribute("data-help-title");
        let helpText = currentBlock.getAttribute("data-help-text");
        let columnHeaders = currentBlock.getAttribute("data-column-headers");
        let invertTableAxis = currentBlock.getAttribute("data-table-invert-axis")?.toLowerCase() === "true";
        let columnSortOrder = asIntOrDefault(currentBlock.getAttribute("data-table-column-order"));
        let helpModelStyle = currentBlock.getAttribute("data-help-model-style");

        return {
            Title: helpTitle,
            Text: helpText,
            ColumnHeaders: columnHeaders,
            InvertTableAxis: invertTableAxis,
            ColumnSortOrder: columnSortOrder ? columnSortOrder : null,
            ModelStyle: helpModelStyle
        };
    };
    const getRequestFields = (currentBlock: Element, fieldSuffix?: string, catVariableDefault?: number, divideBy?: number):
        { ApiUrl: string, RequestBody: string, XAxisVariableIndex: number, CategoryVariableIndex: number, DivideValueBy: number } => {
        fieldSuffix = fieldSuffix ?? "";
        let apiUrl = currentBlock.getAttribute("data-api-url" + fieldSuffix);
        let requestBody = currentBlock.getAttribute("data-request-body" + fieldSuffix);
        let xAxisVariableIndexString = currentBlock.getAttribute("data-x-axis-variable-index" + fieldSuffix);
        let categoryVariableIndexString = currentBlock.getAttribute("data-category-variable-index" + fieldSuffix);
        let divideValueByString = currentBlock.getAttribute("data-divide-value-by" + fieldSuffix);

        return {
            ApiUrl: apiUrl,
            RequestBody: requestBody,
            XAxisVariableIndex: asIntOrDefault(xAxisVariableIndexString),
            CategoryVariableIndex: asIntOrDefault(categoryVariableIndexString, catVariableDefault),
            DivideValueBy: asIntOrDefault(divideValueByString, divideBy ?? 1),
        };
    };
    const getPrognoseFields = (currentBlock: Element):
        { ApiUrl: string, RequestBody: string, XAxisVariableIndex: number, CategoryVariableIndex: number, DivideValueBy: number, Text: string } => {

        let prognoseApiUrl = currentBlock.getAttribute("data-prognose-api-url");
        let prognoseRequestBody = currentBlock.getAttribute("data-prognose-request-body");
        let prognoseXAxisVariableIndexString = currentBlock.getAttribute("data-prognose-x-axis-variable-index");
        let prognoseCategoryVariableIndexString = currentBlock.getAttribute("data-prognose-category-variable-index");
        let prognoseDivideValueByString = currentBlock.getAttribute("data-prognose-divide-value-by");

        let prognoseText = currentBlock.getAttribute("data-prognose-text");

        return {
            ApiUrl: prognoseApiUrl,
            RequestBody: prognoseRequestBody,
            XAxisVariableIndex: asIntOrDefault(prognoseXAxisVariableIndexString),
            CategoryVariableIndex: asIntOrDefault(prognoseCategoryVariableIndexString),
            DivideValueBy: asIntOrDefault(prognoseDivideValueByString, 1),
            Text: prognoseText
        };
    };
    const getBaseFields = (currentBlock: Element):
        { Type: number, HeadTitle: string, NumberOfDecimals: number, BlockId: string, LastPublished: string } => {

        let typeString = currentBlock.getAttribute("data-graph-type");
        let headTitle = currentBlock.getAttribute("data-head-title");
        let numberOfDecimals = currentBlock.getAttribute("data-no-decimals");
        let blockId = currentBlock.getAttribute("data-block-id");
        let lastPublished = currentBlock.getAttribute("data-last-published");

        return {
            Type: asIntOrDefault(typeString),
            HeadTitle: headTitle,
            NumberOfDecimals: asIntOrDefault(numberOfDecimals, null),
            BlockId: blockId,
            LastPublished: lastPublished
        };
    };

    if (document.getElementsByClassName('energipresentation-bubbles-block-react-container').length) {
        let energipresentationBubbles = document.getElementsByClassName('energipresentation-bubbles-block-react-container');

        for (let i = 0; i < energipresentationBubbles.length; i++) {
            let currentBlock = energipresentationBubbles[i];

            let valuePrefix = currentBlock.getAttribute("data-value-prefix");
            let valueSuffix = currentBlock.getAttribute("data-value-suffix");

            let base = getBaseFields(currentBlock);
            let request = getRequestFields(currentBlock);
            let prognose = getPrognoseFields(currentBlock);

            let useCommonFilter = currentBlock.getAttribute("data-use-common-filter").toLowerCase() === "true";

            ReactDOM.render(

                <EnergipresentationBubbles
                    key={"bubbles_" + base.BlockId}
                    headTitle={base.HeadTitle}

                    apiUrl={request.ApiUrl}
                    requestBody={request.RequestBody}
                    xAxisVariableIndex={request.XAxisVariableIndex}
                    categoryVariableIndex={request.CategoryVariableIndex}
                    divideValueBy={request.DivideValueBy}

                    prognoseApiUrl={prognose.ApiUrl}
                    prognoseRequestBody={prognose.RequestBody}
                    prognoseXAxisVariableIndex={prognose.XAxisVariableIndex}
                    prognoseCategoryVariableIndex={prognose.CategoryVariableIndex}
                    prognoseDivideValueBy={prognose.DivideValueBy}
                    prognoseText={prognose.Text}

                    valuePrefix={valuePrefix}
                    valueSuffix={valueSuffix}
                    blockId={base.BlockId}
                    lastPublished={base.LastPublished}
                    useCommonFilter={useCommonFilter}
                />, currentBlock);
        }
    }

    if (document.getElementsByClassName('energipresentation-double-bubbles-block-react-container').length) {
        let energipresentationDoubleBubbles = document.getElementsByClassName('energipresentation-double-bubbles-block-react-container');

        for (let i = 0; i < energipresentationDoubleBubbles.length; i++) {
            let currentBlock = energipresentationDoubleBubbles[i];

            let valuePrefix = currentBlock.getAttribute("data-value-prefix");
            let valueSuffix = currentBlock.getAttribute("data-value-suffix");

            let doCalculateQuotaString = currentBlock.getAttribute("data-do-calculate-quota");
            let doCalculateQuota = doCalculateQuotaString.toLowerCase() === "true";

            let base = getBaseFields(currentBlock);
            let request1 = getRequestFields(currentBlock, "-1");
            let request2 = getRequestFields(currentBlock, "-2");
            let prognose = getPrognoseFields(currentBlock);

            let useCommonFilter = currentBlock.getAttribute("data-use-common-filter").toLowerCase() === "true";

            ReactDOM.render(
                <EnergipresentationDoubleBubbles
                    key={"double_bubbles_" + base.BlockId}
                    headTitle={base.HeadTitle}

                    apiUrl1={request1.ApiUrl}
                    requestBody1={request1.RequestBody}
                    xAxisVariableIndex1={request1.XAxisVariableIndex}
                    categoryVariableIndex1={request1.CategoryVariableIndex}
                    divideValueBy1={request1.DivideValueBy}

                    apiUrl2={request2.ApiUrl}
                    requestBody2={request2.RequestBody}
                    xAxisVariableIndex2={request2.XAxisVariableIndex}
                    categoryVariableIndex2={request2.CategoryVariableIndex}
                    divideValueBy2={request2.DivideValueBy}

                    prognoseApiUrl={prognose.ApiUrl}
                    prognoseRequestBody={prognose.RequestBody}
                    prognoseXAxisVariableIndex={prognose.XAxisVariableIndex}
                    prognoseCategoryVariableIndex={prognose.CategoryVariableIndex}
                    prognoseDivideValueBy={prognose.DivideValueBy}
                    prognoseText={prognose.Text}

                    valuePrefix={valuePrefix}
                    valueSuffix={valueSuffix}
                    doCalculateQuota={doCalculateQuota}
                    blockId={base.BlockId}
                    lastPublished={base.LastPublished}
                    useCommonFilter={useCommonFilter}
                />, currentBlock);
        }
    }

    if (document.getElementsByClassName('energipresentation-graph-block-react-container').length) {
        let energipresentationGraph = document.getElementsByClassName('energipresentation-graph-block-react-container');

        for (let i = 0; i < energipresentationGraph.length; i++) {
            let currentBlock = energipresentationGraph[i];

            let yAxisName = currentBlock.getAttribute("data-y-axis-name");
            let xAxisShowNthString = currentBlock.getAttribute("data-x-axis-show-nth");
            let specialTypeString = currentBlock.getAttribute("data-special-type");

            let base = getBaseFields(currentBlock);
            let request = getRequestFields(currentBlock);
            let prognose = getPrognoseFields(currentBlock);
            let help = getAccordionFields(currentBlock);

            ReactDOM.render(<EnergipresentationGraph
                key={"graph_" + base.BlockId}
                type={base.Type}
                headTitle={base.HeadTitle}
                decimals={base.NumberOfDecimals}
                yAxisName={yAxisName}
                valueSuffix={yAxisName}

                apiUrl={request.ApiUrl}
                requestBody={request.RequestBody}
                xAxisVariableIndex={request.XAxisVariableIndex}
                categoryVariableIndex={request.CategoryVariableIndex}
                divideValueBy={request.DivideValueBy}

                prognoseApiUrl={prognose.ApiUrl}
                prognoseRequestBody={prognose.RequestBody}
                prognoseXAxisVariableIndex={prognose.XAxisVariableIndex}
                prognoseCategoryVariableIndex={prognose.CategoryVariableIndex}
                prognoseDivideValueBy={prognose.DivideValueBy}

                specialType={asIntOrDefault(specialTypeString)}
                xAxisShowNth={asIntOrDefault(xAxisShowNthString, 1)}

                helpTitle={help.Title}
                helpText={help.Text}
                helpColumnHeaders={help.ColumnHeaders}
                helpInvertTableAxis={help.InvertTableAxis}
                helpColumnSortOrder={help.ColumnSortOrder}
                helpModelStyle={help.ModelStyle}

                blockId={base.BlockId}
                lastPublished={base.LastPublished}
            />, currentBlock);
        }
    }

    if (document.getElementsByClassName('energipresentation-double-graph-block-react-container').length) {
        let energipresentationGraph = document.getElementsByClassName('energipresentation-double-graph-block-react-container');

        for (let i = 0; i < energipresentationGraph.length; i++) {
            let currentBlock = energipresentationGraph[i];

            let yAxisName = currentBlock.getAttribute("data-y-axis-name");

            let xAxisShowNthString = currentBlock.getAttribute("data-x-axis-show-nth");
            let doCalculateQuotaString = currentBlock.getAttribute("data-do-calculate-quota");
            let doCalculateQuota = doCalculateQuotaString.toLowerCase() === "true";
            let specialTypeString = currentBlock.getAttribute("data-special-type");
            let specialType = asIntOrDefault(specialTypeString);

            let base = getBaseFields(currentBlock);
            let request1 = getRequestFields(currentBlock, "-one");
            let request2 = getRequestFields(currentBlock, "-two");
            let prognose = getPrognoseFields(currentBlock);
            let help = getAccordionFields(currentBlock);

            ReactDOM.render(<EnergipresentationDoubleGraph
                key={"double_graph_" + i}
                type={base.Type}
                headTitle={base.HeadTitle}
                decimals={base.NumberOfDecimals}
                yAxisName={yAxisName}
                valueSuffix={yAxisName}

                apiUrlOne={request1.ApiUrl}
                requestBodyOne={request1.RequestBody}

                apiUrlTwo={request2.ApiUrl}
                requestBodyTwo={request2.RequestBody}

                xAxisVariableIndex={request1.XAxisVariableIndex}
                categoryVariableIndex={request1.CategoryVariableIndex}

                prognoseApiUrl={prognose.ApiUrl}
                prognoseRequestBody={prognose.RequestBody}
                prognoseXAxisVariableIndex={prognose.XAxisVariableIndex}
                prognoseCategoryVariableIndex={prognose.CategoryVariableIndex}
                prognoseDivideValueBy={prognose.DivideValueBy}

                doCalculateQuota={doCalculateQuota}
                specialType={specialType}
                xAxisShowNth={asIntOrDefault(xAxisShowNthString, 1)}

                helpTitle={help.Title}
                helpText={help.Text}
                helpColumnHeaders={help.ColumnHeaders}
                helpInvertTableAxis={help.InvertTableAxis}
                helpColumnSortOrder={help.ColumnSortOrder}
                helpModelStyle={help.ModelStyle}

                blockId={base.BlockId}
                lastPublished={base.LastPublished}
            />, currentBlock);
        }
    }

    if (document.getElementsByClassName("energipresentation-scales-block-react-container").length) {
        let energipresentationScales = document.getElementsByClassName("energipresentation-scales-block-react-container");

        for (let i = 0; i < energipresentationScales.length; i++) {
            let currentBlock = energipresentationScales[i];

            let resultTextSourceB = currentBlock.getAttribute("data-result-text-source-b");
            let resultTextSourceC = currentBlock.getAttribute("data-result-text-source-c");
            let descriptionDifference = currentBlock.getAttribute("data-description-difference");
            let titleLeftSide = currentBlock.getAttribute("data-title-left-side");
            let titleRightSide = currentBlock.getAttribute("data-title-right-side");

            let base = getBaseFields(currentBlock);
            let requestA = getRequestFields(currentBlock, "-source-a", 1, 1000);
            let requestB = getRequestFields(currentBlock, "-source-b", 1, 1000);
            let requestC = getRequestFields(currentBlock, "-source-c", 1, 1000);
            let help = getAccordionFields(currentBlock);

            ReactDOM.render(<EnergipresentationScales
                key={"scales_" + base.BlockId}
                headTitle={base.HeadTitle}

                apiUrlSourceAEltillforsel={requestA.ApiUrl}
                requestBodySourceAEltillforsel={requestA.RequestBody}
                xAxisVariableIndexSourceA={requestA.XAxisVariableIndex}
                categoryVariableIndexSourceA={requestA.CategoryVariableIndex}
                divideBySourceA={requestA.DivideValueBy}

                apiUrlSourceBEnergianvandning={requestB.ApiUrl}
                requestBodySourceBEnergianvandning={requestB.RequestBody}
                xAxisVariableIndexSourceB={requestB.XAxisVariableIndex}
                categoryVariableIndexSourceB={requestB.CategoryVariableIndex}
                divideBySourceB={requestB.DivideValueBy}

                apiUrlSourceCEnergianvandningBranslen={requestC.ApiUrl}
                requestBodySourceCEnergianvandningBranslen={requestC.RequestBody}
                xAxisVariableIndexSourceC={requestC.XAxisVariableIndex}
                categoryVariableIndexSourceC={requestC.CategoryVariableIndex}
                divideBySourceC={requestC.DivideValueBy}

                resultTextSourceB={resultTextSourceB}
                resultTextSourceC={resultTextSourceC}
                descriptionDifference={descriptionDifference}
                titleLeftSide={titleLeftSide}
                titleRightSide={titleRightSide}

                helpTitle={help.Title}
                helpText={help.Text}
                helpColumnHeaders={help.ColumnHeaders}
                helpInvertTableAxis={help.InvertTableAxis}
                helpModelStyle={help.ModelStyle}

                blockId={base.BlockId}
                lastPublished={base.LastPublished}
            />, currentBlock);
        }
    }

    if (document.getElementsByClassName('energipresentation-circle-block-react-container').length) {
        let energipresentationCircle = document.getElementsByClassName('energipresentation-circle-block-react-container');

        for (let i = 0; i < energipresentationCircle.length; i++) {
            let currentBlock = energipresentationCircle[i];

            let xAxisVariableIndex = 0;
            let categoryVariableIndex = 0;

            let yAxisName = "yAxisName";
            let xAxisShowNth = 1;

            let base = getBaseFields(currentBlock);
            let request = getRequestFields(currentBlock);
            let help = getAccordionFields(currentBlock);

            ReactDOM.render(<EnergipresentationCircle
                key={"pie_" + base.BlockId}
                type={base.Type}
                headTitle={base.HeadTitle}
                decimals={base.NumberOfDecimals}

                apiUrl={request.ApiUrl}
                requestBody={request.RequestBody}
                xAxisVariableIndex={xAxisVariableIndex}
                categoryVariableIndex={categoryVariableIndex}

                yAxisName={yAxisName}
                xAxisShowNth={xAxisShowNth}

                helpTitle={help.Title}
                helpText={help.Text}
                helpColumnHeaders={help.ColumnHeaders}
                helpInvertTableAxis={help.InvertTableAxis}
                helpColumnSortOrder={help.ColumnSortOrder}
                helpModelStyle={help.ModelStyle}

                blockId={base.BlockId}
                lastPublished={base.LastPublished}
            />, currentBlock);
        }
    }

    if (document.getElementsByClassName('energipresentation-sankey-block-react-container').length) {
        let energipresentationSankey = document.getElementsByClassName('energipresentation-sankey-block-react-container');

        for (let i = 0; i < energipresentationSankey.length; i++) {
            let currentBlock = energipresentationSankey[i];

            let valueSuffix = currentBlock.getAttribute("data-value-suffix");
            var contentId = currentBlock.getAttribute("aria-labelledby").split("_")[1];

            let base = getBaseFields(currentBlock);
            let help = getAccordionFields(currentBlock);

            ReactDOM.render(<EnergipresentationSankey
                key={"sankey_" + base.BlockId}
                headTitle={base.HeadTitle}
                decimals={base.NumberOfDecimals}
                valueSuffix={valueSuffix}
                cId={asIntOrDefault(contentId, null)}

                helpTitle={help.Title}
                helpText={help.Text}
                helpColumnHeaders={help.ColumnHeaders}
                helpInvertTableAxis={help.InvertTableAxis}
                helpModelStyle={help.ModelStyle}

                blockId={base.BlockId}
                lastPublished={base.LastPublished}
            />, currentBlock);
        }
    }

    if (document.getElementsByClassName('energipresentation-filter-block-react-container').length) {
        let energipresentationFilter = document.getElementsByClassName('energipresentation-filter-block-react-container');
        let bubblesBlocks = document.getElementsByClassName("energipresentation-bubbles-block-react-container");
        let toUseCommon = 0;
        for (let i = 0; i < bubblesBlocks.length; i++) {
            if (bubblesBlocks[i].getAttribute("data-use-common-filter").toLowerCase() == "true") {
                toUseCommon++;
            }
        }
        let doubleBubblesBlocks = document.getElementsByClassName("energipresentation-double-bubbles-block-react-container");
        for (let i = 0; i < doubleBubblesBlocks.length; i++) {
            if (doubleBubblesBlocks[i].getAttribute("data-use-common-filter").toLowerCase() == "true") {
                toUseCommon++;
            }
        }

        for (let i = 0; i < energipresentationFilter.length; i++) {
            let currentBlock = energipresentationFilter[i];
            let title = currentBlock.getAttribute("data-title");
            let textField = currentBlock.getAttribute("data-text");
            let linkedBlockId = currentBlock.getAttribute("data-linked-block-id");
            let year1DefaultValueString = currentBlock.getAttribute("data-year1-default");
            let year2DefaultValueString = currentBlock.getAttribute("data-year2-default");
            let firstLoadString = currentBlock.getAttribute("data-first-load");
            let firstLoad = firstLoadString.toLowerCase() === "true";
            let label1 = currentBlock.getAttribute("data-label1");
            let label2 = currentBlock.getAttribute("data-label2");
            let singleSelectString = currentBlock.getAttribute("data-single-select");
            let singleSelect = singleSelectString.toLowerCase() === "true";

            let year1DefaultValue: number = 0;
            year1DefaultValue = asIntOrDefault(year1DefaultValueString, null);

            let year2DefaultValue: number = 0;
            year2DefaultValue = asIntOrDefault(year2DefaultValueString, null);

            ReactDOM.render(
                <EnergipresentationFilter
                    key={"filter_" + linkedBlockId}
                    title={title}
                    textField={textField}
                    bubblesBlockCount={toUseCommon}
                    linkedBlockId={linkedBlockId}
                    year1DefaultValue={year1DefaultValue}
                    year2DefaultValue={year2DefaultValue}
                    firstLoad={firstLoad}
                    label1={label1}
                    label2={label2}
                    singleSelect={singleSelect}
                />, currentBlock);
        }
    }
});