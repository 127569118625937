import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as XLSX from 'xlsx';
import iKalkylElefterfragan from './iKalkylElefterfragan';
import iKalkylElefterfraganProps from './iKalkylElefterfraganProps';
import KalkylElefterfraganGraph from './KalkylElefterfraganGraph';
import KalkylElefterfraganTable from './KalkylElefterfraganTable';
import KalkylElefterfraganPopulationTable from './KalkylElefterfraganPopulationTable';

interface PopulationData {
    Rows: any[];
    Name: string;
}

interface DemandData {
    Rows: any[];
    Name: string;
}

const KalkylElefterfragan: React.FC<iKalkylElefterfraganProps> = (props) => {
    const [changeValue, setChangeValue] = useState<number>(1);
    const [demandData, setDemandData] = useState<DemandData>({ Rows: [], Name: '' });
    const [originalDemandData, setOriginalDemandData] = useState<DemandData>({ Rows: [], Name: '' });
    const [factorData, setFactorData] = useState({});
    const [unit, setUnit] = useState('GWh');
    const [productionData, setProductionData] = useState('');
    const [populationData, setPopulationData] = useState<PopulationData>({ Rows: [], Name: '' });
    const [originalPopulationData, setOriginalPopulationData] = useState<PopulationData>({ Rows: [], Name: '' });

    let initialValues: iKalkylElefterfragan = {
        props: props,
        showResult: false,
        isCleared: true,
        isCalculated: false,
        isValid: false,

        county: props.countyDefaultValue,

        installedEffectMin: 1,
        installedEffectMax: 999,
        installedEffectIsValid: false,
        installedEffectIsFocused: false,
        isLoading: false,
    };

    const [state, setState] = useState(initialValues);
    const [selectedCountyId, setSelectedCountyId] = useState<number>(state.county);
    const [selectedCountyName, setSelectedCountyName] = useState<string>("");

    const helpTextMap = {
        'Industri, befintlig': props.helptextIndustryExisting,
        'Industri, utökad och kommande etableringar': props.helptextIndustryUpcoming,
        'Lätta vägtransporter': props.helptextTransportLight,
        'Tunga vägtransporter': props.helptextTransportHeavy,
        'Bostäder': props.helptextResidences,
        'Service': props.helptextService,
    };

    useEffect(() => {
        const initialCounty = props.counties.find(county => county.Id === state.county);
        if (initialCounty) {
            setSelectedCountyName(initialCounty.Text);
        }
    }, [props.counties, state.county]);


    const convertProductionData = (production) => {
        if (unit.toLowerCase() === 'gwh') {
            return parseInt(production) / 1000;
        } else if (unit.toLowerCase() === 'twh') {
            return parseInt(production) / 1000000;
        }

        return parseInt(production);
    }

    const getChartData = (production, demand) => {
        let seriesObject2;
        const series: { name: string, data: number[] }[] = [];
        const productionData = {
            name: 'Elproduktion i länet, 2022',
            data: [convertProductionData(production)],
        }
        series.push(productionData);

        demand.Rows.forEach(row => {
            const lastCell = row.Cells[row.Cells.length - 1];
            const firstCell = row.Cells[0];

            if (firstCell.StringValue) {
                const name = firstCell.StringValue;
                const values = row.Cells.slice(1, 4).map(cell => {
                    const value = parseFloat(cell.NumberValue);
                    return value;
                });

                const valuesUpperRange = row.Cells.slice(1, 4).map(cell => {
                    let value = parseFloat(cell.NumberValueUpperRange);
                    if (value) {
                        value = (parseFloat(cell.NumberValueUpperRange) - parseFloat(cell.NumberValue));
                    }
                    return value;
               
                });


                const seriesObject = {
                    name: name,
                    data: [0, ...values]
                };

                const seriesObjectUpperRange = {
                    name: `${name} spann övre`,
                    data: [0, ...valuesUpperRange]
                };

                series.push(seriesObject);
                series.push(seriesObjectUpperRange);

            }
        });
        return series;
    };

    const combineData = data => {
        let combinedData = [];

        combinedData.push(data.find(item => item.name === "Elproduktion i länet, 2022"));
        combinedData.push(data.find(item => item.name === "Industri, befintlig spann övre"));
        combinedData.push(data.find(item => item.name === "Industri, befintlig"));
        combinedData.push(data.find(item => item.name === "Industri, utökad och kommande etableringar"));

        let transportData = data.filter(item => item.name.includes("vägtransporter"));
        if (transportData.length > 0) {
            let combinedTransport = {
                name: "Vägtransporter",
                data: transportData.reduce((acc, curr) => {
                    curr.data.forEach((value, index) => {
                        acc[index] = (acc[index] || 0) + value;
                    });
                    return acc;
                }, [0, 0, 0, 0])
            };
            combinedData.push(combinedTransport);
        }

        let housingAndServiceData = data.filter(item => item.name.includes("Bostäder") || item.name.includes("Service"));
        if (housingAndServiceData.length > 0) {
            let combinedHousingAndService = {
                name: "Bostäder och Service",
                data: housingAndServiceData.reduce((acc, curr) => {
                    curr.data.forEach((value, index) => {
                        acc[index] = (acc[index] || 0) + value;
                    });
                    return acc;
                }, [0, 0, 0, 0])
            };
            combinedData.push(combinedHousingAndService);
        }

        return combinedData.concat(data.filter(item => !item.name.includes("Transportsektorn") && item.name.includes("Bostäder") && item.name.includes("Service")));
    };

    const handleExport = () => {
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Framtida elefterfrågan",
            Subject: "Elanvändning",
            CreatedDate: new Date()
        };

        const demandSheetData = demandData.Rows.map(row => row.Cells.map(cell => {
            let value = cell.NumberValue;
            let valueUpperRange = cell.NumberValueUpperRange;
            if (value === 0) {
                value = '';
            }
            if (valueUpperRange && valueUpperRange > value) {
                value = `${value}-${valueUpperRange}`;
            }

            return cell.StringValue || value;
        }));

        const sumRow = ["Summa"];
        for (let colIndex = 1; colIndex < demandSheetData[0].length - 1; colIndex++) {
            let minSum = 0, maxSum = 0;
            let hasRange = false;

            demandSheetData.slice(1).forEach(row => {
                const value = row[colIndex];

                if (value) {
                    if (typeof value === "string" && value.includes("-")) {
                        const [min, max] = value.split("-").map(parseFloat);
                        minSum += min;
                        maxSum += max;
                        hasRange = true;
                    } else {
                        const numberValue = parseFloat(value) || 0;
                        minSum += numberValue;
                        maxSum += numberValue;
                    }
                }
            });

            sumRow.push(hasRange ? `${minSum}-${maxSum}` : (minSum === 0 ? "" : minSum.toString()));
        }
        sumRow.push(unit);
        demandSheetData.push(sumRow);

        const populationSheetData = populationData.Rows.map(row => row.Cells.map(cell => {
            let value = cell.NumberValue;
            if (value === 0) {
                value = '';
            } else {
                value = Math.round(cell.NumberValue);
            }
            return cell.StringValue || value;
        }));

        const combinedData = [
            ["Län", selectedCountyName],
            [],
            ["Befolkningsmängd"],
            ...populationSheetData,
            [],
            ["Efterfrågan på el"],
            ...demandSheetData,
            [],
            ["Elproduktion i länet"],
            [convertProductionData(productionData), unit]
        ];
        const combinedSheet = XLSX.utils.aoa_to_sheet(combinedData);

        XLSX.utils.book_append_sheet(wb, combinedSheet, "Framtida elefterfrågan");

        XLSX.writeFile(wb, 'kalkyl_elefterfragan_export.xlsx');
    };

    const handleClick = async () => {
        const selectedCounty = props.counties.find(county => county.Id === selectedCountyId);
        if (selectedCounty) {
            setSelectedCountyName(selectedCounty.Text);
            setState(prevState => ({
                ...prevState,
                county: selectedCountyId,
                isLoading: true,
            }));

            try {
                const apiData = await props.fetchApiData("/api/ElectricityDemandApi/GetCountyData", { countyId: selectedCountyId, id: props.blockReference, culture: props.currentLanguage });
                setDemandData(apiData.DemandData);
                setOriginalDemandData(apiData.DemandData);
                setFactorData(apiData.FactorData);
                setProductionData(apiData.ProductionData);
                setPopulationData(apiData.PopulationData);
                setOriginalPopulationData(apiData.PopulationData);
                setChangeValue(1);
                setUnit(apiData.DemandData.Rows[1].Cells[4].StringValue);
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                }));


            } catch (error) {
                console.error('Error fetching data on county change:', error);
            }
        }
    };

    const getText = (text) => {
        if (text === null || text === undefined || text === "") {
            return ""
        }

        return text;
    }

    const getNewValue = (changeValue, factor, value) => {
        return ((changeValue - 1) * value * factor) + value;
    }

    const findFactor = (name, year, factorData) => {
        const factorRow = factorData.Rows.find(row => row.Cells[0].StringValue === name);
        if (!factorRow) return 1;
        switch (year) {
            case '2022':
                return factorRow.Cells[1].NumberValue;
            case '2030':
                return factorRow.Cells[2].NumberValue;
            case '2045':
                return factorRow.Cells[3].NumberValue;
            default:
                return 1;
        }
    }

    const calculateData = (changeRate, newValue, index) => {
        const originalDemand = JSON.parse(JSON.stringify(originalDemandData));

        originalDemand.Rows.forEach((row, rowIndex) => {
            row.Cells.forEach((cell, cellIndex) => {
                if (cell.NumberValue && cellIndex > 1) {
                    const name = row.Cells[0].StringValue;
                    if (!name.includes('Industri')) {
                        const year = originalDemand.Rows[0].Cells[cellIndex].StringValue;

                        if (index === 2) {
                            const factor = findFactor(name, year, factorData);
                            originalDemand.Rows[rowIndex].Cells[cellIndex].NumberValue = getNewValue(changeRate, factor, cell.NumberValue);
                            originalDemand.Rows[rowIndex].Cells[3].NumberValue = demandData.Rows[rowIndex].Cells[3].NumberValue;
                        } else if (index === 3) {
                            const factor = findFactor(name, year, factorData);
                            originalDemand.Rows[rowIndex].Cells[cellIndex].NumberValue = getNewValue(changeRate, factor, cell.NumberValue);
                            originalDemand.Rows[rowIndex].Cells[2].NumberValue = demandData.Rows[rowIndex].Cells[2].NumberValue;
                        } else {
                            const factor = findFactor(name, year, factorData);
                            originalDemand.Rows[rowIndex].Cells[cellIndex].NumberValue = getNewValue(changeRate, factor, cell.NumberValue);
                        }
                    }
                }
            });
        });

        const originalPopulation = JSON.parse(JSON.stringify(originalPopulationData));

        originalPopulation.Rows[1].Cells.forEach((cell, cellIndex) => {
            if (cell.NumberValue && cellIndex > 1) {
                const year = originalDemand.Rows[0].Cells[cellIndex].StringValue;

                if (index === 2) {
                    originalPopulation.Rows[1].Cells[cellIndex].NumberValue = newValue;
                    originalPopulation.Rows[1].Cells[3].NumberValue = populationData.Rows[1].Cells[3].NumberValue;
                } else if (index === 3) {
                    originalPopulation.Rows[1].Cells[cellIndex].NumberValue = newValue;
                    originalPopulation.Rows[1].Cells[2].NumberValue = populationData.Rows[1].Cells[2].NumberValue;
                } else {
                    originalPopulation.Rows[1].Cells[cellIndex].NumberValue = newValue;
                }
            }
        });

        setDemandData(originalDemand);
        setPopulationData(originalPopulation);

    };

    const handlePopulationInputChange = (value: string, cell: number) => {
        const originalPopulation = JSON.parse(JSON.stringify(originalPopulationData)).Rows[1].Cells[cell].NumberValue;
        const changeRate = Math.round(parseInt(value)) / originalPopulation;

        calculateData(changeRate, value, cell);
    };

    const handleDemandInputChange = (value: string, cell: number, row: number) => {
        const demand = JSON.parse(JSON.stringify(demandData));
        demand.Rows[row + 1].Cells[cell].NumberValue = value;
        setDemandData(demand);

        const originalDemand = JSON.parse(JSON.stringify(originalDemandData));
        originalDemand.Rows[row + 1].Cells[cell].NumberValue = value;
        setOriginalDemandData(originalDemand);
    };

    const handleCountyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCountyId(Number(event.target.value));
    };

    return (
        <div>
            <div className="col-md-12">
                <div className="custom-select">
                    <label htmlFor="county">Välj län</label>
                    <select name="county" id="county" value={selectedCountyId} onChange={handleCountyChange}>
                        <option value='' disabled={selectedCountyId > 0}>
                            Välj ett län
                        </option>
                        {props.counties.map(county => (
                            <option key={county.Id} value={county.Id}>
                                {county.Text}
                            </option>
                        ))}
                    </select>
                    <span className="glyphicon glyphicon-chevron-down"></span>
                </div>
                <button disabled={!selectedCountyId} type="button" onClick={handleClick} className="btn get-data btn-calculate" title={getText(state.props.buttonCalculate)}>{getText(state.props.buttonCalculate)}</button>
            </div>
            <div className="col-md-12">
                {state.isLoading && (
                    <div id="loader-container">
                        <div id="loader-spinner"></div>
                    </div>
                )}
                {populationData && populationData.Rows.length > 0 && (
                    <div className="component-container">
                        <h3>Befolkningsmängd</h3>
                        <div>
                            <KalkylElefterfraganPopulationTable data={populationData} value={changeValue} handlePopulationInputChange={handlePopulationInputChange} originalData={originalPopulationData} />
                        </div>
                    </div>
                )}
                {demandData && demandData.Rows.length > 0 && (
                    <div className="component-container">
                        <h3>{demandData.Name}</h3>
                        <div>
                            <KalkylElefterfraganTable unit={unit} data={demandData} handleDemandInputChange={handleDemandInputChange} helpTexts={helpTextMap} />
                        </div>
                    </div>
                )}
                {demandData && demandData.Rows.length > 0 && (
                    <div className="component-container">
                        <h3>Diagram</h3>
                        <div className='graph-container'>
                            <KalkylElefterfraganGraph unit={unit} series={combineData(getChartData(productionData, demandData))} />
                        </div>
                    </div>
                )}

                {demandData && demandData.Rows.length > 0 && (
                    <div className='button-container'>
                        <button disabled={!selectedCountyId} type="button" onClick={handleExport} className="btn btn-calculate" title="Exportera">Exportera</button>
                        <button disabled={!selectedCountyId} type="button" onClick={handleClick} className="btn btn-reset" title={getText(state.props.buttonReset)}>{getText(state.props.buttonReset)}</button>
                    </div>
                )}

                {demandData.Rows.length === 0 && demandData.Rows.length === 0 && (
                    <div className="no-results">
                        <p>Välj ett län i listan ovan för att hämta data om framtida elefterfrågan</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default KalkylElefterfragan;
