import iEnergipresentationScaleApiRequestModel from "./RequestModels/iEnergipresentationScaleApiRequest";
import iEnergipresentationCircleApiRequestModel from "./RequestModels/iEnergipresentationCircleApiRequest";
import iEnergipresentationSankeyApiRequestModel from "./RequestModels/iEnergipresentationSankeyApiRequest";
import iEnergipresentationBubbleApiRequestModel from "./RequestModels/iEnergipresentationBubbleApiRequest";
import iEnergipresentationDoubleSourceBubbleApiRequestModel from "./RequestModels/iEnergipresentationDoubleSourceBubbleApiRequest";
import iEnergipresentationRectangleGraphApiRequestModel from "./RequestModels/iEnergipresentationRectangleGraphApiRequest";
import iEnergipresentationDoubleSourceGraphApiRequestModel from "./RequestModels/iEnergipresentationDoubleSourceGraphApiRequest";
import iEnergipresentationYearsModel from "./iEnergipresentationYears";

export default class DataHelper {

    private constructor() { }

    public static async FetchGraphData(apiRequest: iEnergipresentationRectangleGraphApiRequestModel, callback): Promise<void> {
        let fetchCallback = (response: any) => {
            if (!response) {
                callback(response);
            }
            else {
                let cleanedJson = this.removeUnusedProperties(response);
                callback(cleanedJson);
            }
        };
        await this.performFetch(apiRequest, 'GetChartDataAsync', fetchCallback);
    };

    public static async FetchDoubleGraphData(apiRequest: iEnergipresentationDoubleSourceGraphApiRequestModel, callback): Promise<void> {
        let fetchCallback = (response: any) => {
            if (!response) {
                callback(response);
            }
            else {
                let cleanedJson = this.removeUnusedProperties(response);
                callback(cleanedJson);
            }
        };
        await this.performFetch(apiRequest, 'GetDoubleChartDataAsync', fetchCallback);
    };

    public static async FetchScaleData(apiRequest: iEnergipresentationScaleApiRequestModel, callback, newYears?: iEnergipresentationYearsModel): Promise<void> {
        let fetchCallback = (response: any) => {
            if (!response) {
                callback(response);
            }
            else {
                let cleanedJson = this.removeUnusedProperties(response);
                callback(cleanedJson, newYears);
            }
        };
        await this.performFetch(apiRequest, 'GetScaleDataAsync', fetchCallback);
    };

    public static async FetchCircleData(apiRequest: iEnergipresentationCircleApiRequestModel, callback): Promise<void> {
        await this.performFetch(apiRequest, 'GetCircleDataAsync', callback);
    };

    public static async FetchSankeyData(apiRequest: iEnergipresentationSankeyApiRequestModel, callback, newYears?: iEnergipresentationYearsModel): Promise<void> {
        let fetchCallback = (response: any) => {
            if (!response) {
                callback(response);
            }
            else {
                let cleanedJson = this.removeUnusedProperties(response);
                callback(cleanedJson, newYears);
            }
        };
        await this.performFetch(apiRequest, 'GetSankeyDataAsync', fetchCallback);
    };

    public static async FetchGraphOverviewData(apiRequest: iEnergipresentationBubbleApiRequestModel, callback, newYears?: iEnergipresentationYearsModel): Promise<void> {
        let fetchCallback = (response: any) => {
            if (!response) {
                callback(response);
            }
            else {
                callback(response, newYears);
            }
        };
        await this.performFetch(apiRequest, 'GetChartOverviewDataAsync', fetchCallback);
    };

    public static async FetchDoubleGraphOverviewData(apiRequest: iEnergipresentationDoubleSourceBubbleApiRequestModel, callback, newYears?: iEnergipresentationYearsModel): Promise<void> {
        let fetchCallback = (response: any) => {
            if (!response) {
                callback(response);
            }
            else {
                callback(response, newYears);
            }
        };
        await this.performFetch(apiRequest, 'GetDoubleChartOverviewDataAsync', fetchCallback);
    };

    private static pascalToCamel(input: string): string {
        if (!input || input[0] === input[0].toLowerCase()) {
            return input;
        }

        let firstChar = input[0].toLowerCase();
        let rest = input.slice(1);
        return `${firstChar}${rest}`;
    };

    private static transformKeysToCamelCase(obj: any, call?:number): any {
        if (typeof obj !== "object" || obj == null) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(i => this.transformKeysToCamelCase(i, (call??0)+1));
        }

        let newObj = {};

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let newKey = this.pascalToCamel(key);
                let value = obj[key];
                newObj[newKey] = this.transformKeysToCamelCase(value);
            }
        }

        return newObj;
    };

    private static removeUnusedProperties(obj: any): any {
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map(i => this.removeUnusedProperties(i));
        }

        let result: any = {};

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let value = this.removeUnusedProperties(obj[key]);

                if (value !== null && value !== undefined) {
                    result[key] = value;
                }
            }
        }

        return result;
    };

    private static removeIndentFromJsonString(jsonString: string): string {
        const jsonObject = JSON.parse(jsonString);
        const compactJsonString = JSON.stringify(jsonObject);
        return compactJsonString;
    };

    private static async performFetch(apiRequest: any, endPoint: string, callback, debug?: boolean): Promise<void> {
        try {
            if (apiRequest.requests) {
                for (let i = 0; i < apiRequest.requests.length; i++) {
                    if (debug && apiRequest.requests[i].requestBody.length === 0) {
                        console.log("requestBody is empty for request " + i + " to ", endPoint);
                    }
                    apiRequest.requests[i].requestBody = this.removeIndentFromJsonString(apiRequest.requests[i].requestBody);
                }
            }

            apiRequest['lang'] = document.getElementsByTagName('html')[0].lang;
            if (debug) { console.log('req', apiRequest); }
            await fetch("/api/EnergipresentationApi/" + endPoint, {
                credentials: 'same-origin',
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                keepalive: true,
                body: JSON.stringify(apiRequest)
            }).then(response => response.json()
                .then((json) => {
                    if (debug) {
                        console.log("performFetch: " + endPoint, "json", json);
                    }
                    if (!response.ok) {
                        let domainStartIndex = apiRequest.requests[0].apiUrl.indexOf("//") + 2;
                        let domainEndIndex = apiRequest.requests[0].apiUrl.substring(domainStartIndex).indexOf("/");
                        let domain = apiRequest.requests[0].apiUrl.substring(domainStartIndex, domainEndIndex + domainStartIndex)
                        throw Error("Något gick fel vid hämtning från " + domain + ": " + (json ? json.ExceptionMessage : json));
                    }
                    else {
                        let newJson = this.transformKeysToCamelCase(json)
                        callback(newJson);
                    }
                })
                .catch((err) => {
                    console.error("Något gick fel i " + endPoint + ": ", err);
                    callback();
                })
            );
        }
        catch (err) {
            console.error("Något gick fel i try-catch: ", err);
            callback(false)
        }
    };
};