import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import iElstodsFormularModel from './iElstodsFormular';

const initialValues: iElstodsFormularModel = {
    //Årsomsättning
    arsOmsattning: "",

    //Total elanvändning exkl. uppvärmning
    totAnvKWhOktDec2022: "",
    totAnvKWhOktDec2021: "",
    totAnvKWh2021: "",

    //Kostnad elanvändning exkl. uppvärmning
    kostAnvSEKOktDec2022: "",
    kostAnvSEK2021: "",

    //Uträkningar
    kvotElanvandningArsomsattning: "0",
    stodberattigandeElAnvKWh: "0",
    stodberattigandeElKost: "0",
    calculationSummary: "",

    //Visa resultat
    showCalculationResults: false,
    showResult: false,
    isNegativeResult: false
}

interface iFormularProps {
    showAsHorizontal: boolean,
    titleConsumption: string,
    titleCost: string,
    buttonTitle: string,
    resetButtonTitle: string,
    labelAnnualTurnover: string,
    labelPeriodYear2: string,
    labelPeriodYear1: string,
    labelFullYear1: string,
    labelQuota: string,
    labelEligibleUse: string,
    labelEligibleCost: string,
    explanationQuota: string,
    explanationEligibleUse: string,
    explanationEligibleCost: string,
    suffixCurrency: string,
    suffixElectricity: string,
    suffixQuota: string,
    preSummary: string,
    postSummary: string,
    negativeSummary: string,
    titleCalculation: string,
    hideQuota: boolean,
    hideEligibleUse: boolean,
    hideEligibleCost: boolean

}

const ElstodsFormular = (props: iFormularProps) => {
    const [state, setState] = useState(initialValues);

    useEffect(() => {
    }, []);

    const addThousandSeparators = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const setDefaultValues = () => {
        let newState = { ...initialValues };
        setState(newState);
    };

    const handleChange = (event) => {
        let value = event.target.value;
        let propertyName = event.target.name;

        setState({
            ...state,
            [propertyName]: addThousandSeparators(removeNonNumeric(value))
        });
    }

    const getText = (text) => {
        if (text === null || text === undefined || text === "") {
            return ""
        }

        return text;
    }

    const parseNumeric = (num) => {
        return parseInt(removeNonNumeric(num));
    }

    const calculateElkostnadsstod = () => {
        if (state.arsOmsattning !== null && state.arsOmsattning != ""
            && state.totAnvKWhOktDec2022 !== null && state.totAnvKWhOktDec2022 != ""
            && state.totAnvKWhOktDec2021 !== null && state.totAnvKWhOktDec2021 != ""
            && state.totAnvKWh2021 !== null && state.totAnvKWh2021 != ""
            && state.kostAnvSEKOktDec2022 !== null && state.kostAnvSEKOktDec2022 != ""
            && state.kostAnvSEK2021 !== null && state.kostAnvSEK2021 != "") {
            let newState = { ...state };
            let quota = parseNumeric(state.totAnvKWh2021) / parseNumeric(state.arsOmsattning);
            newState.kvotElanvandningArsomsattning = (quota).toFixed(3);

            let oktDec2021Diff = parseNumeric(state.totAnvKWhOktDec2021) * 0.7;
            let oktDec2022Diff = parseNumeric(state.totAnvKWhOktDec2022);
            newState.stodberattigandeElAnvKWh = addThousandSeparators(Math.round(oktDec2021Diff < oktDec2022Diff ? oktDec2021Diff : oktDec2022Diff).toString());

            let stodberattigandeElAnvKWh = parseNumeric(newState.stodberattigandeElAnvKWh);
            let kostAnvSEKOktDec2022 = parseNumeric(newState.kostAnvSEKOktDec2022);
            let totAnvKWhOktDec2022 = parseNumeric(newState.totAnvKWhOktDec2022);
            let kostAnvSEK2021 = parseNumeric(newState.kostAnvSEK2021);
            let totAnvKWh2021 = parseNumeric(newState.totAnvKWh2021);
            let avgElCostOktDec2022 = kostAnvSEKOktDec2022 / totAnvKWhOktDec2022;
            let avgElCost2021 = kostAnvSEK2021 / totAnvKWh2021;

            const minstastodberattigandeElAnv = 50000;
            const minQuota = 0.015;

            let utrakning = stodberattigandeElAnvKWh * (avgElCostOktDec2022 - (avgElCost2021 * 1.5)) * 0.5;
            utrakning = Math.round(utrakning);
            let utrakningString = utrakning < 0 ? "0" : utrakning.toString()
            newState.stodberattigandeElKost = addThousandSeparators(utrakningString);
            newState.calculationSummary = addThousandSeparators(parseNumeric(utrakningString));
            newState.showCalculationResults = true;
            newState.isNegativeResult = (utrakning < minstastodberattigandeElAnv) || (quota < minQuota);

            setState(newState);
        }
    }

    const clearAll = () => {
        setState(initialValues);
    }

    const toggleResult = () => {
        let newState = { ...state };
        newState.showResult = !newState.showResult;
        setState(newState);
    }

    return (
        <React.Fragment>
            {props.showAsHorizontal && (
                <React.Fragment>
                    <div className="col-md-6 form-container">
                        <div className="col-md-6 ">
                            <label>{getText(props.labelAnnualTurnover)}</label>
                        </div>
                        <div className="col-md-6">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.arsOmsattning} onChange={handleChange} name="arsOmsattning" /><span className="suffix">{getText(props.suffixCurrency)}</span>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <h3>{getText(props.titleConsumption)}</h3>
                        </div>
                        <div className="col-md-6">
                            <label>{getText(props.labelPeriodYear2)}</label>
                        </div>
                        <div className="col-md-6">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.totAnvKWhOktDec2022} onChange={handleChange} name="totAnvKWhOktDec2022" /><span className="suffix">{getText(props.suffixElectricity)}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>{getText(props.labelPeriodYear1)}</label>
                        </div>
                        <div className="col-md-6">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.totAnvKWhOktDec2021} onChange={handleChange} name="totAnvKWhOktDec2021" /><span className="suffix">{getText(props.suffixElectricity)}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>{getText(props.labelFullYear1)}</label>
                        </div>
                        <div className="col-md-6">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.totAnvKWh2021} onChange={handleChange} name="totAnvKWh2021" /><span className="suffix">{getText(props.suffixElectricity)}</span>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <h3>{getText(props.titleCost)}</h3>
                        </div>
                        <div className="col-md-6">
                            <label>{getText(props.labelPeriodYear2)}</label>
                        </div>
                        <div className="col-md-6">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.kostAnvSEKOktDec2022} onChange={handleChange} name="kostAnvSEKOktDec2022" /><span className="suffix">{getText(props.suffixCurrency)}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>{getText(props.labelFullYear1)}</label>
                        </div>
                        <div className="col-md-6">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.kostAnvSEK2021} onChange={handleChange} name="kostAnvSEK2021" /><span className="suffix">{getText(props.suffixCurrency)}</span>
                            </div>
                        </div>

                        <div className="col-md-12 buttons-container">
                            <button type="button" className="btn btn-calculate" title={getText(props.buttonTitle)} onClick={calculateElkostnadsstod}>{getText(props.buttonTitle)}</button>
                            <button type="button" className="btn btn-reset" title={getText(props.resetButtonTitle)} onClick={clearAll}>{getText(props.resetButtonTitle)}</button>
                            <div className="clear"></div>
                        </div>

                    </div>
                    {!state.showCalculationResults && (
                        <div className="col-md-6 calculation-results grey-box">
                        </div>
                    )}
                    {state.showCalculationResults && (
                        <div className="calculation-results">
                            <div className="col-md-6">
                                {(state.showCalculationResults && !state.isNegativeResult) && (
                                    <div className="calculation-summary">
                                        <div className="approved"></div>
                                        <div className="text">{getText(props.preSummary)} <span className="bold-summary">{state.calculationSummary}</span> {getText(props.postSummary)}</div>
                                    </div>
                                )}
                                {(state.showCalculationResults && state.isNegativeResult) && (
                                    <div className="calculation-summary">
                                        <div className="not-approved"></div>
                                        <div className="text">{getText(props.negativeSummary)}</div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 result-header" onClick={toggleResult}>
                                <h3>{getText(props.titleCalculation)}</h3>
                            </div>
                            <div className="col-md-6 result-container">
                                {!props.hideQuota && (
                                    <React.Fragment>
                                        <div className="col-md-12">
                                            <label>
                                                <h3>{getText(props.labelQuota)}</h3>
                                                <span className="explanation">{getText(props.explanationQuota)}</span>
                                            </label>
                                        </div>
                                        <div className="col-md-12 result-text">
                                            <span>{state.kvotElanvandningArsomsattning}</span><span className="small-suffix">{getText(props.suffixQuota)}</span>
                                        </div>
                                    </React.Fragment>
                                )}

                                {!props.hideEligibleUse && (
                                    <React.Fragment>
                                        <div className="col-md-12">
                                            <label>
                                                <h3>{getText(props.labelEligibleUse)}</h3>
                                                <span className="explanation">{getText(props.explanationEligibleUse)}</span>
                                            </label>
                                        </div>
                                        <div className="col-md-12 result-text">
                                            <span>{state.stodberattigandeElAnvKWh}</span><span className="small-suffix">{getText(props.suffixElectricity)}</span>
                                        </div>
                                    </React.Fragment>
                                )}

                                {!props.hideEligibleCost && (
                                    <React.Fragment>
                                        <div className="col-md-12">
                                            <label>
                                                <h3>{getText(props.labelEligibleCost)}</h3>
                                                <span className="explanation">{getText(props.explanationEligibleCost)}</span>
                                            </label>
                                        </div>
                                        <div className="col-md-12 result-text">
                                            <span>{state.stodberattigandeElKost}</span><span className="small-suffix">{getText(props.suffixCurrency)}</span>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )}



                </React.Fragment>

            )}
            {
                !props.showAsHorizontal && (
                    <React.Fragment>
                        <div className="col-md-3 col-md-offset-3">
                            <label>{getText(props.labelAnnualTurnover)}</label>
                        </div>
                        <div className="col-md-4">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.arsOmsattning} onChange={handleChange} name="arsOmsattning" /><span className="suffix">{getText(props.suffixCurrency)}</span>
                            </div>
                        </div>

                        <div className="col-md-7 col-md-offset-3">
                            <h3>{getText(props.titleConsumption)}</h3>
                        </div>
                        <div className="col-md-3 col-md-offset-3">
                            <label>{getText(props.labelPeriodYear2)}</label>
                        </div>
                        <div className="col-md-4">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.totAnvKWhOktDec2022} onChange={handleChange} name="totAnvKWhOktDec2022" /><span className="suffix">{getText(props.suffixElectricity)}</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-md-offset-3">
                            <label>{getText(props.labelPeriodYear1)}</label>
                        </div>
                        <div className="col-md-4">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.totAnvKWhOktDec2021} onChange={handleChange} name="totAnvKWhOktDec2021" /><span className="suffix">{getText(props.suffixElectricity)}</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-md-offset-3">
                            <label>{getText(props.labelFullYear1)}</label>
                        </div>
                        <div className="col-md-4">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.totAnvKWh2021} onChange={handleChange} name="totAnvKWh2021" /><span className="suffix">{getText(props.suffixElectricity)}</span>
                            </div>
                        </div>

                        <div className="col-md-7 col-md-offset-3">
                            <h3>{getText(props.titleCost)}</h3>
                        </div>
                        <div className="col-md-3 col-md-offset-3">
                            <label>{getText(props.labelPeriodYear2)}</label>
                        </div>
                        <div className="col-md-4">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.kostAnvSEKOktDec2022} onChange={handleChange} name="kostAnvSEKOktDec2022" /><span className="suffix">{getText(props.suffixCurrency)}</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-md-offset-3">
                            <label>{getText(props.labelFullYear1)}</label>
                        </div>
                        <div className="col-md-4">
                            <div className="textbox-with-suffix-container">
                                <input type="tel" value={state.kostAnvSEK2021} onChange={handleChange} name="kostAnvSEK2021" /><span className="suffix">{getText(props.suffixCurrency)}</span>
                            </div>
                        </div>

                        <div className="col-md-7  col-md-offset-3 buttons-container">
                            <button type="button" className="btn btn-calculate" title={getText(props.buttonTitle)} onClick={calculateElkostnadsstod}>{getText(props.buttonTitle)}</button>
                            <button type="button" className="btn btn-reset" title={getText(props.resetButtonTitle)} onClick={clearAll}>{getText(props.resetButtonTitle)}</button>
                            <div className="clear"></div>
                        </div>

                        <div className={(state.showCalculationResults ? "calculation-results" : "calculation-results hidden")}>
                            <div className="col-md-7 col-md-offset-3">
                                {(state.showCalculationResults && !state.isNegativeResult) && (
                                    <div className="calculation-summary">
                                        <div className="approved"></div>
                                        <div className="text">{getText(props.preSummary)} <span className="bold-summary">{state.calculationSummary}</span> {getText(props.postSummary)}</div>
                                    </div>
                                )}
                                {(state.showCalculationResults && state.isNegativeResult) && (
                                    <div className="calculation-summary">
                                        <div className="not-approved"></div>
                                        <div className="text">{getText(props.negativeSummary)}</div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-7 col-md-offset-3 result-header" onClick={toggleResult}>
                                <h3>{getText(props.titleCalculation)}</h3>
                            </div>
                            <div className="col-md-7 col-md-offset-3 result-container">

                                {!props.hideQuota && (
                                    <React.Fragment>
                                        <div className="col-md-12">
                                            <label>
                                                <h3>{getText(props.labelQuota)}</h3>
                                                <span className="explanation">{getText(props.explanationQuota)}</span>
                                            </label>
                                        </div>
                                        <div className="col-md-12 result-text">
                                            <span>{state.kvotElanvandningArsomsattning}</span><span className="small-suffix">{getText(props.suffixQuota)}</span>
                                        </div>
                                    </React.Fragment>
                                )}

                                {!props.hideEligibleUse && (
                                    <React.Fragment>
                                        <div className="col-md-12">
                                            <label>
                                                <h3>{getText(props.labelEligibleUse)}</h3>
                                                <span className="explanation">{getText(props.explanationEligibleUse)}</span>
                                            </label>
                                        </div>
                                        <div className="col-md-12 result-text">
                                            <span>{state.stodberattigandeElAnvKWh}</span><span className="small-suffix">{getText(props.suffixElectricity)}</span>
                                        </div>
                                    </React.Fragment>
                                )}

                                {!props.hideEligibleCost && (
                                    <React.Fragment>
                                        <div className="col-md-12">
                                            <label>
                                                <h3>{getText(props.labelEligibleCost)}</h3>
                                                <span className="explanation">{getText(props.explanationEligibleCost)}</span>
                                            </label>
                                        </div>
                                        <div className="col-md-12 result-text">
                                            <span>{state.stodberattigandeElKost}</span><span className="small-suffix">{getText(props.suffixCurrency)}</span>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )
            }




        </React.Fragment >
    );
};
export default ElstodsFormular;
