import React from 'react';
import SortOrder from './SortOrderEnum';
import iSeriesItem from './ResponseModels/iSeriesItem';
import iSankeySeriesItem from './ResponseModels/iSankeySeriesItem';
import iCircleDataPost from './ResponseModels/iCircleDataPost';
import iScaleData from './ResponseModels/iScaleData';
import DisplayHelper from './DisplayHelper';
import * as XLSX from 'xlsx';

const EnergipresentationExportButton = ({ series, type, title, heading, unit, sortOrder }:
    { series: Array<iSeriesItem | iSankeySeriesItem | iCircleDataPost> | iScaleData, type: string, title: string, heading: Array<string>, unit: string, sortOrder?: SortOrder }) => {
    const exportToExcel = () => {
        const sheetName = DisplayHelper.LocaleText('Graph data export', 'Grafdata export');
        const chartTitle = title || sheetName;

        const sanitizedSheetName = chartTitle.substring(0, 31).replace(/[\[\]:*?/\\]/g, '');

        var worksheetData = DisplayHelper.ToWorksheetData(series, type, title, heading, unit, null, sortOrder);

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);

        XLSX.writeFile(workbook, `${sanitizedSheetName || sheetName}.xlsx`);
    };
    const buttonText = ():string => {
        return DisplayHelper.LocaleText('Export', 'Exportera');
    };

    return (
        <React.Fragment>
            <button className="btn btn-export" onClick={exportToExcel}>{buttonText()}</button>
        </React.Fragment >
    );
};

export default EnergipresentationExportButton;
