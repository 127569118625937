import * as React from 'react';
import { useState, useEffect } from 'react';
import EnergipresentationAccordion from './EnergipresentationAccordion';
import iEnergipresentationCircleModel from './iEnergipresentationCircle';
import iEnergipresentationCirclePropsModel from './iEnergipresentationCircleProps';
import DataHelper from './DataHelper';
import DisplayHelper from './DisplayHelper';
import iCircleData from './ResponseModels/iCircleData';
import iEnergipresentationRequestModel from './RequestModels/iEnergipresentationRequest';
import iEnergipresentationCircleApiRequestModel from './RequestModels/iEnergipresentationCircleApiRequest';
import * as Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import highchartsAccessibility from "highcharts/modules/accessibility";
import EnergipresentationExportButton from './EnergipresentationExportButton';
import EventBus from '../EventBus';

const EnergipresentationCircle = (props: iEnergipresentationCirclePropsModel) => {

    const [delayed, setDelayed] = useState(true);
    const [state, setState] = useState<iEnergipresentationCircleModel>(props);

    useEffect(() => {
        highchartsAccessibility(Highcharts);
        getCircleData();
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 3000);
        return () => clearTimeout(timeout);
    }, []);

    const getCircleData = async () => {
        let request: iEnergipresentationRequestModel = {
            apiUrl: state.apiUrl,
            requestBody: state.requestBody,
            xAxisVariableIndex: state.xAxisVariableIndex,
            categoryVariableIndex: state.categoryVariableIndex,
            divideValueBy: 1,
        };
        let apiRequest: iEnergipresentationCircleApiRequestModel = {
            requests: [request],
            type: state.type,
            yAxisName: state.yAxisName,
            xAxisShowNth: state.xAxisShowNth,
            decimals: state.decimals,
            blockId: state.blockId,
            lastPublished: state.lastPublished
        };

        await DataHelper.FetchCircleData(apiRequest, initializeCircle);
    };

    const initializeCircle = (circleData: iCircleData) => {
        let newState = { ...state };

        newState.textReplacements = DisplayHelper.GetTextReplacementsFromData(circleData);
        newState.headTitle = DisplayHelper.ReflectDataInText(newState.headTitle, newState.textReplacements);
        circleData.title.text = null;
        circleData.chart.marginTop = 20;

        newState.circleData = circleData;
        setState(newState);
        if (newState.circleData != null) {
            EventBus.dispatch("GraphDataLoaded", newState.blockId);
        }
    };

    const addSpecificHelpText = (cmsText: string): string => {
        if (!state.circleData?.series) {
            return cmsText;
        }
        let heading = getTableHeading();
        let result = DisplayHelper.ReflectDataInText(cmsText, state.textReplacements);
        result += DisplayHelper.ToHTMLTable(state.circleData.series, state.circleData.chart.type, state.headTitle, heading, state.decimals, '%', state.helpInvertTableAxis, state.helpColumnSortOrder);
        return result;
    };

    const getTableHeading = () => {
        return (DisplayHelper.ReflectDataInText(state.helpColumnHeaders, state.textReplacements, true)?.split(DisplayHelper.DataDelimiter) || ['','%']) ?? [];
    };

    return (
        <React.Fragment>
            <h3>{state.headTitle}</h3>
            <div className="circle-container">
                <div className="scroll-container">
                    {(state.circleData != null || delayed) ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={state.circleData}
                        />
                        :
                        <div>
                            <p><span className="label label-danger">Fel vid rendering av cirkeldiagram</span></p>
                            <p><strong>URL:</strong> <code>{state.apiUrl}</code></p>
                            <p><strong>RequestBody:</strong> <code>{state.requestBody}</code></p>
                        </div>
                    }
                </div>
            </div>
            {(state.circleData != null || delayed) && (
                <EnergipresentationExportButton
                    series={state.circleData?.series}
                    type={state.circleData?.chart?.type}
                    title={state.headTitle}
                    heading={[]}
                    unit={'%'}
                    sortOrder={state.helpColumnSortOrder}
                />
            )}
            {(state.circleData != null || delayed) && state.helpText && state.helpText != "" && (
                <EnergipresentationAccordion
                    key={"accordion_" + state.blockId}
                    title={state.helpTitle}
                    text={addSpecificHelpText(state.helpText)}
                    modelStyle={state.helpModelStyle}
                />
            )}
        </React.Fragment >
    );
};
export default EnergipresentationCircle;