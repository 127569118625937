import * as React from 'react';
import EventBus from '../EventBus';
import { useState, useEffect } from 'react';
import EnergipresentationAccordion from './EnergipresentationAccordion';
import DataHelper from './DataHelper';
import DisplayHelper from './DisplayHelper';
import iEnergipresentationRequestModel from './RequestModels/iEnergipresentationRequest';
import iEnergipresentationScaleApiRequestModel from './RequestModels/iEnergipresentationScaleApiRequest';
import iEnergipresentationScalesModel from './iEnergipresentationScales';
import iEnergipresentationScalesPropsModel from './iEnergipresentationScalesProps';
import iEnergipresentationScalesResponse from './iEnergipresentationScalesResponse';
import iEnergipresentationYearsModel from './iEnergipresentationYears';
import iEnergipresentationMinMaxYearsModel from './iEnergipresentationMinMaxYears';
import iScalesItemModel from './ResponseModels/iScalesItem';
import iScaleData from './ResponseModels/iScaleData';
import EnergipresentationExportButton from './EnergipresentationExportButton';


const EnergipresentationScales = (props: iEnergipresentationScalesPropsModel) => {
    const initialState: iEnergipresentationScalesModel = {
        ...props,
        isLoading: true,
        year: "0",
        scaleData: {} as iScaleData,
    };

    const [delayed, setDelayed] = useState(true);
    const [state, setState] = useState<iEnergipresentationScalesModel>(initialState);

    useEffect(() => {
        setYears();

        EventBus.on("UpdateBlockYears_" + state.blockId, updateSelectedYears);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 3000);
        return () => clearTimeout(timeout);
    }, []);

    const updateSelectedYears = async (years: iEnergipresentationYearsModel, data: iEnergipresentationScalesResponse = null) => {
        if (!hasValues(state.sourceAEltillforselValues) && !hasValues(data?.sourceAEltillforselValues) &&
            !hasValues(state.sourceBEnergianvandningValues) && !hasValues(data?.sourceBEnergianvandningValues) &&
            !hasValues(state.sourceCEnergianvandningBranslenValues) && !hasValues(data?.sourceCEnergianvandningBranslenValues)) {

            let customNewYears: iEnergipresentationYearsModel = { firstYear: years.firstYear };
            await fetchScaleData(customNewYears, updateSelectedYearFromApi);
            return;
        }

        let isLoading = false;
        if (!hasValues(state.sourceAEltillforselValues) && hasValues(data?.sourceAEltillforselValues) &&
            !hasValues(state.sourceBEnergianvandningValues) && hasValues(data?.sourceBEnergianvandningValues) &&
            !hasValues(state.sourceCEnergianvandningBranslenValues) && hasValues(data?.sourceCEnergianvandningBranslenValues)) {

            data.year = years.firstYear.toString();
            updateState(data, isLoading);
            return;
        }

        let newState = { ...state };
        newState.isLoading = isLoading;
        setState(newState);
    };

    const hasValues = (list: Array<any>): boolean => {
        return list !== undefined && list !== null && list.length > 0;
    };

    const updateSelectedYearFromApi = async (data: iEnergipresentationScalesResponse, years: iEnergipresentationYearsModel) => {
        await updateSelectedYears(years, data);
    };

    const fetchScaleData = async (newYears: iEnergipresentationYearsModel, callback) => {
        let sourceAEltillforsel: iEnergipresentationRequestModel = {
            name: 'A',
            apiUrl: state.apiUrlSourceAEltillforsel,
            categoryVariableIndex: state.categoryVariableIndexSourceA,
            requestBody: state.requestBodySourceAEltillforsel,
            xAxisVariableIndex: state.xAxisVariableIndexSourceA,
            divideValueBy: 1,
        };

        let sourceBEnergianvandning: iEnergipresentationRequestModel = {
            name: 'B',
            apiUrl: state.apiUrlSourceBEnergianvandning,
            categoryVariableIndex: state.categoryVariableIndexSourceB,
            requestBody: state.requestBodySourceBEnergianvandning,
            xAxisVariableIndex: state.xAxisVariableIndexSourceB,
            divideValueBy: 1,
        };

        let sourceCEnergianvandningBranslen: iEnergipresentationRequestModel = {
            name: 'C',
            apiUrl: state.apiUrlSourceCEnergianvandningBranslen,
            categoryVariableIndex: state.categoryVariableIndexSourceC,
            requestBody: state.requestBodySourceCEnergianvandningBranslen,
            xAxisVariableIndex: state.xAxisVariableIndexSourceC,
            divideValueBy: 1,
        };

        let scaleApiRequest: iEnergipresentationScaleApiRequestModel = {
            requests: [sourceAEltillforsel, sourceBEnergianvandning, sourceCEnergianvandningBranslen],
            blockId: state.blockId,
            lastPublished: state.lastPublished
        };

        await DataHelper.FetchScaleData(scaleApiRequest, callback, newYears);
    };

    const initializeScale = (data: iEnergipresentationScalesResponse) => {
        if (!hasValues(data?.sourceAEltillforselValues) || !hasValues(data?.sourceBEnergianvandningValues) || !hasValues(data?.sourceCEnergianvandningBranslenValues)) {
            EventBus.dispatch("ToggleBlock_" + state.blockId, null);
        }

        let newState = updateState(data);
        newState.scaleData.descriptionDifference = newState.descriptionDifference;
        newState.scaleData.titleLeftSide = newState.titleLeftSide;
        newState.scaleData.titleRightSide = newState.titleRightSide;
        newState.scaleData.resultTextSourceB = newState.resultTextSourceB;
        newState.scaleData.resultTextSourceC = newState.resultTextSourceC;

        let minYearKey = newState.availableYears[0];
        let maxYearKey = newState.availableYears[newState.availableYears.length - 1];
        let minYear: number = minYearKey !== undefined && minYearKey !== null ? parseInt(minYearKey) : -1;
        let maxYear: number = maxYearKey !== undefined && maxYearKey !== null ? parseInt(maxYearKey) : -1;
        let currentYears: iEnergipresentationMinMaxYearsModel = { minYear: minYear, maxYear: maxYear };
        EventBus.dispatch("LoadedBlock_" + newState.blockId, currentYears);
    };

    const updateState = (data: iEnergipresentationScalesResponse, isLoading: boolean = true): iEnergipresentationScalesModel => {
        var newState = { ...state };

        newState.sourceAEltillforselValues = mapValues(data.sourceAEltillforselValues, state.divideBySourceA, true);
        newState.sourceBEnergianvandningValues = mapValues(data.sourceBEnergianvandningValues, state.divideBySourceB);
        newState.sourceCEnergianvandningBranslenValues = mapValues(data.sourceCEnergianvandningBranslenValues, state.divideBySourceC);

        newState.yearIndex = data.availableYears.indexOf(data.year);

        newState.scaleData.sourceAValues = newState.sourceAEltillforselValues.map((a) => { return { key: a.key, value: [a.value[newState.yearIndex]] }; });
        newState.scaleData.sourceBValues = newState.sourceBEnergianvandningValues.map((a) => { return { key: a.key, value: [a.value[newState.yearIndex]] }; });
        newState.scaleData.sourceCValues = newState.sourceCEnergianvandningBranslenValues.map((a) => { return { key: a.key, value: [a.value[newState.yearIndex]] }; });
        newState.scaleData.sourceAEltillforselTotal = getTotal(newState.sourceAEltillforselValues, newState.yearIndex);
        newState.scaleData.sourceBEnergianvandningTotal = getTotal(newState.sourceBEnergianvandningValues, newState.yearIndex);
        newState.scaleData.sourceCEnergianvandningBranslenTotal = getTotal(newState.sourceCEnergianvandningBranslenValues, newState.yearIndex);

        newState.scaleData.sourceBAndCTotal = newState.scaleData.sourceBEnergianvandningTotal + newState.scaleData.sourceCEnergianvandningBranslenTotal;
        newState.scaleData.statisticDifference = newState.scaleData.sourceAEltillforselTotal - (newState.scaleData.sourceBAndCTotal);
        newState.scaleData.sourceRightSideTotal = newState.scaleData.sourceBAndCTotal + newState.scaleData.statisticDifference;

        newState.year = data.year;
        newState.availableYears = data.availableYears;

        newState.textReplacements = { FROM: newState.year };

        newState.headTitle = DisplayHelper.ReflectDataInText(newState.headTitle, newState.textReplacements);

        newState.isLoading = isLoading;
        setState(newState);
        if (delayed && newState.scaleData.sourceRightSideTotal) {
            EventBus.dispatch("GraphDataLoaded", newState.blockId);
        }
        return newState;
    };

    const mapValues = (values: iScalesItemModel[], divideBy: number, sort: boolean = false): iScalesItemModel[] => {
        var mapped = values.map((item: iScalesItemModel) => {
            return { ...item, value: item.value.map(v => Math.round(v / divideBy)) };
        });

        if (!sort) {
            return mapped;
        }

        return mapped.sort((itemA, itemB) => {
            if (itemA.key === "Solkraft") {
                return -1;
            } else if (itemB.key === "Solkraft") {
                return 1;
            } else {
                return 0;
            }
        });
    };
    const getTotal = (values: iScalesItemModel[], yearIndex: number): number => {
        if (yearIndex < 0) {
            return 0;
        }

        return values.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.value[yearIndex];
        }, 0);
    };

    const setYears = async (newYears?: iEnergipresentationYearsModel) => {
        if (newYears === undefined || newYears === null) {
            newYears = { firstYear: -1, secondYear: -1 };
        }

        await fetchScaleData(newYears, initializeScale);
    };

    const hasAllValues = (data: any): boolean => {
        if (!data) { return false; }
        if (typeof data === 'number') { return true; }
        if (typeof data === 'string') { return data.length > 0; }
        if (Array.isArray(data)) {
            if (!data.length) { return false; }
            let i = 0;
            for (let item in data) {
                if (!hasAllValues(item,)) { return false; };
            }
            return true;
        }

        if (!hasValues(Object.keys(data))) { return false; }
        for (let key of Object.keys(data)) {
            if (!hasAllValues(data[key])) { return false; }
        }
        return true;
    };

    const addSpecificHelpText = (cmsText: string): string => {
        if (!hasAllValues(state.scaleData)) {
            return cmsText;
        }
        const heading = getTableHeading();
        const decimals = 0;
        const unit = 'TWh';
        let result = DisplayHelper.ReflectDataInText(cmsText, state.textReplacements);
        result += DisplayHelper.ToHTMLTable(state.scaleData, 'scale', state.headTitle, heading, decimals, unit, state.helpInvertTableAxis);
        return result;
    };

    const getTableHeading = () => {
        return DisplayHelper.ReflectDataInText(state.helpColumnHeaders, state.textReplacements, true)?.split(DisplayHelper.DataDelimiter) ?? [];
    };

    return (
        <React.Fragment>
            <h3>{state.headTitle}</h3>
            <div className="scale-wrapper">
                <div className="energipresentation-scale-container">
                    <div className="scroll-container">
                        <div className="image-container">
                            <img src="/Static/img/EnergipresentationScale.png" />
                            <div className="left-side">
                                <div className="title"><div>{state.titleLeftSide}</div></div>
                                {hasValues(state.sourceAEltillforselValues) && (
                                    <React.Fragment>
                                        {state.sourceAEltillforselValues.map((item, itemIndex) => {
                                            return (
                                                <div key={"sourceA-Item-" + itemIndex} className={"box-text box-" + itemIndex}><div>{item.key}&nbsp;<span className="span-value">{item.value[state.yearIndex]}</span></div></div>
                                            )
                                        })}
                                    </React.Fragment>
                                )}
                                <div className="box-text left-total"><p>{state.scaleData.sourceAEltillforselTotal}</p></div>
                            </div>
                            <div className="right-side">
                                <div className="title"><div>{state.titleRightSide}</div></div>
                                <div className="box-text difference-box"><div><span className="span-value">{state.scaleData.statisticDifference}*</span></div></div>
                                {hasValues(state.sourceCEnergianvandningBranslenValues) && (
                                    <React.Fragment>
                                        {state.sourceCEnergianvandningBranslenValues.map((item, itemIndex) => {
                                            return (
                                                <div key={"sourceC-Item-" + itemIndex} className={"box-text box-" + (itemIndex)}><div>{item.key}&nbsp;<span className="span-value">{item.value[state.yearIndex]}</span></div></div>
                                            )
                                        })}
                                    </React.Fragment>
                                )}
                                {hasValues(state.sourceBEnergianvandningValues) && (
                                    <React.Fragment>
                                        {state.sourceBEnergianvandningValues.map((item, itemIndex) => {
                                            return (
                                                <div key={"sourceB-Item-" + itemIndex} className={"box-text box-" + (itemIndex + state.sourceCEnergianvandningBranslenValues.length)}><div>{item.key}&nbsp;<span className="span-value">{item.value[state.yearIndex]}</span></div></div>
                                            )
                                        })}
                                    </React.Fragment>
                                )}
                                <div className="box-text right-total"><p>{state.scaleData.sourceRightSideTotal}</p></div>
                            </div>
                            <div className="current-year-box"><p>{state.year}</p></div>
                            <div className="description-difference-box"><p>{state.descriptionDifference}</p></div>
                        </div>
                        <div className="right-side-totals">
                            <div className="top-total">
                                <div className="text">
                                    {state.resultTextSourceC}
                                    <div className="value">{state.scaleData.sourceCEnergianvandningBranslenTotal} TWh</div>
                                </div>
                            </div>
                            <div className="bottom-total">
                                <div className="text">
                                    {state.resultTextSourceB}
                                    <div className="value">{state.scaleData.sourceBEnergianvandningTotal} TWh</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {((hasValues(state.sourceAEltillforselValues) && hasValues(state.sourceCEnergianvandningBranslenValues) && hasValues(state.sourceBEnergianvandningValues)) || delayed) && (
                <EnergipresentationExportButton
                    series={state.scaleData}
                    type={'scale'}
                    title={state.headTitle}
                    heading={getTableHeading()}
                    unit={'TWh'}
                />
            )}
            {((hasValues(state.sourceAEltillforselValues) && hasValues(state.sourceCEnergianvandningBranslenValues) && hasValues(state.sourceBEnergianvandningValues)) || delayed) &&
                state.helpText && (
                <EnergipresentationAccordion
                    key={"accordion_" + state.blockId}
                    title={state.helpTitle}
                    text={addSpecificHelpText(state.helpText)}
                    modelStyle={state.helpModelStyle}
                />
            )}
        </React.Fragment >
    );
};
export default EnergipresentationScales;
