import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import iHelpText from './iHelpText';

const HelpText = (props: { helpText: string }) => {
    const helpTextRef = useRef<HTMLDivElement>(null);
    const helpIconRef = useRef<HTMLImageElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const initialValues: iHelpText = {
        helpText: props.helpText,
        showHelpText: false
    }
    const [state, setState] = useState(initialValues);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                state.showHelpText &&
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Node)
            ) {

                setState((prevState) => ({
                    ...prevState,
                    showHelpText: false,
                }));
            }
        };

        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.key === "Enter" || event.key === " ") && document.activeElement === wrapperRef.current) {
                event.preventDefault();
                toggleHelpText();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [state.showHelpText]);

    useEffect(() => {
        if (helpIconRef.current) {
            let iconRect = helpIconRef.current.getBoundingClientRect();
            let leftPosition = iconRect.left;

            let newState = { ...state };
            newState.left = leftPosition - 30;
            setState(newState);
        }
    }, [])

    const toggleHelpText = () => {
        let newState = { ...state };
        newState.showHelpText = !newState.showHelpText;
        setState(newState);
    }

    const getTextFromHtmlString = (htmlString: string): string => {
        const doc = new DOMParser().parseFromString(htmlString, "text/html");
        return doc.body.textContent || "";
    }

    return (
        <div
            ref={wrapperRef}
            className="help-text-container"
            onClick={toggleHelpText}
            tabIndex={0}
            role="button"
        >
            <img src="/Static/img/help-icon.svg" alt="Hjälpikon" ref={helpIconRef} title={getTextFromHtmlString(state.helpText)} />
            {state.showHelpText && (
                <div
                    ref={helpTextRef}
                    className="help-text"
                    dangerouslySetInnerHTML={{ __html: state.helpText }}
                    style={{ left: window.innerWidth < 768 ? `-${state.left}px` : "-100px" }}
                />
            )}
        </div>
    );
};

export default HelpText;
