import React, { useState, useEffect } from 'react';

interface Cell {
    StringValue?: string;
    NumberValue?: string;
}

interface Row {
    Cells: Cell[];
}

interface TableData {
    Rows: Row[];
    Name: string;
}

interface KalkylElefterfraganPopulationTableProps {
    data: { Rows: any[]; Name: string };
    originalData: { Rows: any[]; Name: string };
    value: number;
    handlePopulationInputChange: (value: string, cell: number) => void;
}

const KalkylElefterfraganPopulationTable: React.FC<KalkylElefterfraganPopulationTableProps> = ({ data, handlePopulationInputChange, originalData }) => {
    useEffect(() => {
        setLocalData(data);
    }, [data]);

    const [localData, setLocalData] = useState(data);

    const rows = localData.Rows;
    const name = localData.Name;
    const [thead, ...tbody] = rows;

    function useDebounce(callback: Function, delay: number) {
        const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

        useEffect(() => {
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }, []);

        const debouncedCallback = (...args: any[]) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                callback(...args);
            }, delay);
        };

        return debouncedCallback;
    }

    const debouncedHandleInputChange = useDebounce((newValue, cellIndex) => {
        handlePopulationInputChange(newValue, cellIndex);
    }, 600);

    const parseThousandSeparatedValue = (formattedValue) => {
        if (formattedValue.trim() === '') {
            return ''; 
        }
        return parseFloat(formattedValue.replace(/ /g, ''));
    };

    const handleInputChange = (newValue: string, cellIndex: number, rowIndex: number) => {
        const actualValue = parseThousandSeparatedValue(newValue);
        const updatedRows = rows.map((row, rIndex) => {
            if (rIndex === rowIndex + 1) {
                const updatedCells = row.Cells.map((cell, cIndex) => {
                    if (cIndex === cellIndex) {
                        return { ...cell, NumberValue: actualValue };
                    }
                    return cell;
                });
                return { ...row, Cells: updatedCells };
            }
            return row;
        });
        setLocalData({ ...localData, Rows: updatedRows });
        debouncedHandleInputChange(actualValue, cellIndex);
    };

    const addThousandSeparators = (num: number): string => {
        const parts = num.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        if (parts.length > 1) {
            return parts.join(',');
        }
        return parts[0];
    };

    const handleClick = (e) => {
        e.target.select();
    };

    const handleKeyPress = (e) => {
        if (!/[0-9.,\s]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text');
        if (!/^[0-9.,\s]*$/.test(pasteData)) {
            e.preventDefault();
        }
    };

    return (
        <div className='table-container population-table'>
            <i>Angiven befolkningsmängd nedan är enligt prognos. Det finns dock en möjlighet att justera befolkningsmängd för 2030 respektive 2045 utifrån eget önskemål.</i>
            <table className='rs_content'>
                <thead className='hide-mobile'>
                    <tr>
                        {thead.Cells.map((cell, index) => (
                            <th key={index}>
                                {cell.StringValue ? cell.StringValue : ''}
                            </th>
                        ))}
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {tbody.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.Cells.map((cell, cellIndex) => (
                                <td key={cellIndex}>
                                    <span className="mobile-label bold">
                                        {thead.Cells[cellIndex].StringValue}:
                                    </span>
                                    {originalData.Rows.length > 0 && (cellIndex === 2 || cellIndex === 3) ? (
                                            <input
                                                min="0"
                                                type="text"
                                                value={parseInt(cell.NumberValue)}
                                                onChange={(e) => handleInputChange(e.target.value, cellIndex, rowIndex)}
                                                onClick={handleClick}
                                                onKeyUp={handleKeyPress}
                                                onPaste={handlePaste}
                                                />

                                    ) : (
                                        <span>
                                            {cell.StringValue || addThousandSeparators(parseInt(cell.NumberValue || '0'))}
                                        </span>
                                    )}
                                    <span className="mobile-label"> invånare</span>
                                </td>
                            ))}
                            <td className="hide-mobile">Invånare</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default KalkylElefterfraganPopulationTable;
