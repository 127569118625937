import * as React from "react";
import * as ReactDOM from "react-dom";

import KalkylUrsprungsgarantier from './KalkylUrsprungsgarantier';

$(document).ready(function () {
    if (document.getElementsByClassName('kalkyl-ursprungsgarantier-block-react-container')) {
        let kalkylUrsprungsgatantier = document.getElementsByClassName('kalkyl-ursprungsgarantier-block-react-container');

        for (let i = 0; i < kalkylUrsprungsgatantier.length; i++) {
            let currentBlock = kalkylUrsprungsgatantier[i];
            let chooseAnswerType = currentBlock.getAttribute("data-choose-answer-type");
            let buttonCalculate = currentBlock.getAttribute("data-button-calculate");
            let buttonReset = currentBlock.getAttribute("data-button-reset");
            let resultText = currentBlock.getAttribute("data-result-text");
            let resultUnit = currentBlock.getAttribute("data-result-unit");
            let electricityProduction = currentBlock.getAttribute("data-electricity-production");
            let estimatedAnnualProduction = currentBlock.getAttribute("data-estimated-annual-production");
            let estimatedAnnualProductionHelpText = currentBlock.getAttribute("data-estimated-annual-production-helptext");
            let estimatedAnnualProductionUnit = currentBlock.getAttribute("data-estimated-annual-production-unit");
            let estimatedAnnualProductionDefaultValueString = currentBlock.getAttribute("data-estimated-annual-production-default-value");
            let installedEffect = currentBlock.getAttribute("data-installed-effect");
            let installedEffectHelpText = currentBlock.getAttribute("data-installed-effect-helptext");
            let installedEffectUnit = currentBlock.getAttribute("data-installed-effect-unit");
            let installedEffectDefaultValueString = currentBlock.getAttribute("data-installed-effect-default-value");
            let compensation = currentBlock.getAttribute("data-compensation");
            let compensationHelpText = currentBlock.getAttribute("data-compensation-helptext");
            let addedCompensation = currentBlock.getAttribute("data-added-compensation");
            let addedCompensationUnit = currentBlock.getAttribute("data-added-compensation-unit");
            let addedCompensationDefaultValueString = currentBlock.getAttribute("data-added-compensation-default-value");
            let compensationUG = currentBlock.getAttribute("data-compensation-ug");
            let compensationUGUnit = currentBlock.getAttribute("data-compensation-ug-unit");
            let compensationUGDefaultValueString = currentBlock.getAttribute("data-compensation-ug-default-value");
            let annualFee = currentBlock.getAttribute("data-annual-fee");
            let annualFeeDescription = currentBlock.getAttribute("data-annual-fee-description");
            let annualFeeUnit = currentBlock.getAttribute("data-annual-fee-unit");
            let annualFeeDefaultValueString = currentBlock.getAttribute("data-annual-fee-default-value");
            let percentageSolar = currentBlock.getAttribute("data-percentage-solar");
            let ownUsage = currentBlock.getAttribute("data-own-usage");
            let ownUsageUnit = currentBlock.getAttribute("data-own-usage-unit");
            let ownUsageHelpText = currentBlock.getAttribute("data-own-usage-helptext");
            let ownUsageDefaultValueString = currentBlock.getAttribute("data-own-usage-default-value");

            let addedCompensationDefaultValue = parseInt(addedCompensationDefaultValueString);

            if (isNaN(addedCompensationDefaultValue)) {
                addedCompensationDefaultValue = 0;
            }

            let annualFeeDefaultValue = parseInt(annualFeeDefaultValueString);

            if (isNaN(annualFeeDefaultValue)) {
                annualFeeDefaultValue = 0;
            }

            let compensationUGDefaultValue = parseInt(compensationUGDefaultValueString);

            if (isNaN(compensationUGDefaultValue)) {
                compensationUGDefaultValue = 0;
            }

            let estimatedAnnualProductionDefaultValue = parseInt(estimatedAnnualProductionDefaultValueString);

            if (isNaN(estimatedAnnualProductionDefaultValue)) {
                estimatedAnnualProductionDefaultValue = 0;
            }

            let installedEffectDefaultValue = parseInt(installedEffectDefaultValueString);

            if (isNaN(installedEffectDefaultValue)) {
                installedEffectDefaultValue = 0;
            }

            let ownUsageDefaultValue = parseInt(ownUsageDefaultValueString);

            if (isNaN(ownUsageDefaultValue)) {
                ownUsageDefaultValue = 0;
            }

            ReactDOM.render(<KalkylUrsprungsgarantier
                addedCompensation={decodeHtmlEntities(addedCompensation)}
                addedCompensationDefaultValue={addedCompensationDefaultValue}
                addedCompensationUnit={decodeHtmlEntities(addedCompensationUnit)}
                annualFee={decodeHtmlEntities(annualFee)}
                annualFeeDefaultValue={annualFeeDefaultValue}
                annualFeeDescription={decodeHtmlEntities(annualFeeDescription)}
                annualFeeUnit={decodeHtmlEntities(annualFeeUnit)}
                buttonCalculate={decodeHtmlEntities(buttonCalculate)}
                buttonReset={decodeHtmlEntities(buttonReset)}
                chooseAnswerType={decodeHtmlEntities(chooseAnswerType)}
                compensation={decodeHtmlEntities(compensation)}
                compensationHelpText={decodeHtmlEntities(compensationHelpText)}
                compensationUG={decodeHtmlEntities(compensationUG)}
                compensationUGDefaultValue={compensationUGDefaultValue}
                compensationUGUnit={decodeHtmlEntities(compensationUGUnit)}
                electricityProduction={decodeHtmlEntities(electricityProduction)}
                estimatedAnnualProduction={decodeHtmlEntities(estimatedAnnualProduction)}
                estimatedAnnualProductionDefaultValue={estimatedAnnualProductionDefaultValue }
                estimatedAnnualProductionHelpText={decodeHtmlEntities(estimatedAnnualProductionHelpText)}
                estimatedAnnualProductionUnit={decodeHtmlEntities(estimatedAnnualProductionUnit)}
                installedEffect={decodeHtmlEntities(installedEffect)}
                installedEffectDefaultValue={installedEffectDefaultValue}
                installedEffectHelpText={decodeHtmlEntities(installedEffectHelpText)}
                installedEffectUnit={decodeHtmlEntities(installedEffectUnit)}
                ownUsage={decodeHtmlEntities(ownUsage)}
                ownUsageDefaultValue={ownUsageDefaultValue }
                ownUsageHelptText={decodeHtmlEntities(ownUsageHelpText)}
                ownUsageUnit={decodeHtmlEntities(ownUsageUnit)}
                percentageSolar={decodeHtmlEntities(percentageSolar)}
                resultText={decodeHtmlEntities(resultText)}
                resultUnit={decodeHtmlEntities(resultUnit)}
                key={"kalkylUrsprungsgarantier_" + i}
            />, currentBlock);
        }
    }
});

function decodeHtmlEntities(encodedString: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString(`<div>${encodedString}</div>`, 'text/html');
    return dom.body.textContent || "";
}