import * as React from 'react';
import EventBus from '../EventBus';
import { useState, useEffect } from 'react';
import EnergipresentationAccordion from './EnergipresentationAccordion';
import iEnergipresentationSankeyModel from './iEnergipresentationSankey';
import iEnergipresentationSankeyPropsModel from './iEnergipresentationSankeyProps';
import iEnergipresentationSankeyApiRequestModel from './RequestModels/iEnergipresentationSankeyApiRequest';
import iSankeyData from './ResponseModels/iSankeyData';
import iSankeySeriesItem from './ResponseModels/iSankeySeriesItem';
import iEnergipresentationYearsModel from './iEnergipresentationYears';
import { iEnergipresentationMinMaxYearsModel } from './iEnergipresentationMinMaxYears';
import DataHelper from './DataHelper';
import DisplayHelper from './DisplayHelper';
import * as Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import sankey from 'highcharts/modules/sankey';
import highchartsAccessibility from "highcharts/modules/accessibility";
import EnergipresentationExportButton from './EnergipresentationExportButton';

const EnergipresentationSankey = (props: iEnergipresentationSankeyPropsModel) => {
    const initialState: iEnergipresentationSankeyModel = {
        ...props,
        isLoading: true,
        year: "0",
    };

    const [delayed, setDelayed] = useState(true);
    const [state, setState] = useState<iEnergipresentationSankeyModel>(initialState);

    useEffect(() => {
        sankey(Highcharts);
        patternFill(Highcharts);
        highchartsAccessibility(Highcharts);
        setYears();

        EventBus.on("UpdateBlockYears_" + state.blockId, updateSelectedYears);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 3000);
        return () => clearTimeout(timeout);
    }, []);

    const updateSelectedYears = async (years: iEnergipresentationYearsModel, data: iSankeyData = null) => {
        if (!hasValues(state.sankeyData?.series) && !hasValues(data?.series)) {
            let customNewYears: iEnergipresentationYearsModel = { firstYear: years.firstYear };
            await getSankeyData(customNewYears, updateSelectedYearFromApi);
            return;
        }

        let isLoading = false;
        if (!hasValues(state.sankeyData?.series) && hasValues(data?.series)) {
            updateState(data, years, isLoading);
            return;
        }

        let newState = { ...state };
        newState.isLoading = isLoading;
        setState(newState);
    };

    const hasValues = (list: Array<iSankeySeriesItem>): boolean => {
        return list !== undefined && list !== null && list.length > 0;
    };

    const updateSelectedYearFromApi = async (data: iSankeyData, years: iEnergipresentationYearsModel) => {
        await updateSelectedYears(years, data);
    };

    const getSankeyData = async (newYears: iEnergipresentationYearsModel, callback) => {
        let apiRequest: iEnergipresentationSankeyApiRequestModel = {
            cId: state.cId,
            blockId: state.blockId,
            lastPublished: state.lastPublished
        };

        await DataHelper.FetchSankeyData(apiRequest, callback, newYears);
    }

    const initializeGraph = (sankeyData: iSankeyData, years: iEnergipresentationYearsModel) => {
        let newState = updateState(sankeyData, years);

        let minYearKey = newState.availableYears[0];
        let maxYearKey = newState.availableYears[newState.availableYears.length - 1];
        let minYear: number = minYearKey !== undefined && minYearKey !== null ? parseInt(minYearKey) : -1;
        let maxYear: number = maxYearKey !== undefined && maxYearKey !== null ? parseInt(maxYearKey) : -1;
        let currentYears: iEnergipresentationMinMaxYearsModel = { minYear: minYear, maxYear: maxYear };
        EventBus.dispatch("LoadedBlock_" + newState.blockId, currentYears);
    };

    const updateState = (sankeyData: iSankeyData, years: iEnergipresentationYearsModel, isLoading: boolean = true): iEnergipresentationSankeyModel => {
        var newState = { ...state };

        if (!sankeyData || !sankeyData.series || sankeyData.series.length === 0) {
            EventBus.dispatch("ToggleBlock_" + state.blockId, null);
            newState.year = (new Date(Date.now()).getFullYear() - 1) + "";
            newState.availableYears = [newState.year];
            newState.errorText = DisplayHelper.LocaleText('No data', 'Ingen data');
            setState(newState);
            return newState;
        }

        newState.year = years.firstYear.toString();
        newState.availableYears = sankeyData.series.map(s => s.name);

        newState.textReplacements = { FROM: newState.year };
        newState.headTitle = DisplayHelper.ReflectDataInText(newState.headTitle, newState.textReplacements);
        sankeyData.title.text = null;
        sankeyData.chart.marginTop = 20;

        newState.series = [...sankeyData.series];
        sankeyData.series = sankeyData.series.filter(s => s.name === newState.year);

        sankeyData.series.forEach((serie) => {
            serie.clip = false;
            serie.minLinkWidth = 2;

            let hasBackwardLinks = serie.data.some(link => {
                const fromNode = serie.nodes.find(n => n.name === link.from);
                const toNode = serie.nodes.find(n => n.name === link.to);

                return fromNode && toNode && toNode.column < fromNode.column;
            });
            if (hasBackwardLinks) {
                sankeyData.chart.marginBottom = 60;
            }

            serie.nodes.forEach((n) => {
                var base = n.offsetVertical ?? 0;
                n.offsetVertical = !n.height ? n.offsetVertical : base - 7;
                n.dataLabels.allowOverlap = true;
            });
        });
        sankeyData.plotOptions.sankey.nodePadding = 5;
        sankeyData.plotOptions.sankey.nodeWidth = 40;
        sankeyData.plotOptions.sankey['curveFactor'] = 0.66;
        sankeyData.chart.height = 550;

        newState.sankeyData = sankeyData;
        newState.isLoading = isLoading;

        setState(newState);
        if (delayed && newState.sankeyData.series.length) {
            EventBus.dispatch("GraphDataLoaded", newState.blockId);
        }
        return newState;
    };

    const setYears = async (newYears?: iEnergipresentationYearsModel) => {
        if (newYears === undefined || newYears === null) {
            newYears = { firstYear: -1, secondYear: -1 };
        }

        await getSankeyData(newYears, initializeGraph);
    };

    const addSpecificHelpText = (cmsText: string): string => {
        if (!state.sankeyData?.series?.length) {
            return cmsText;
        }
        let heading = getTableHeading();
        let result = DisplayHelper.ReflectDataInText(cmsText, state.textReplacements);
        result += DisplayHelper.ToHTMLTable(state.sankeyData?.series, state.sankeyData.chart.type, state.headTitle, heading, state.decimals, state.valueSuffix, state.helpInvertTableAxis)
        return result;
    };

    const getTableHeading = () => {
        return DisplayHelper.ReflectDataInText(state.helpColumnHeaders, state.textReplacements, true)?.split(DisplayHelper.DataDelimiter) ?? [];
    };

    return (
        <React.Fragment>
            <h3>{state.headTitle}</h3>
            <div className="sankey-container">
                <div className="scroll-container">
                    {(state.sankeyData != null || delayed) ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={state.sankeyData}
                        />
                        :
                        <div>
                            <p><span className="label label-danger">Fel vid rendering av sankey</span></p>
                            <p><code>{state.errorText}</code></p>
                        </div>
                    }
                </div>
            </div>
            {(state.sankeyData != null || delayed) && (
                <EnergipresentationExportButton
                    series={state.sankeyData?.series}
                    type={state.sankeyData?.chart?.type}
                    title={state.headTitle}
                    heading={[]}
                    unit={state.valueSuffix}
                />
            )}
            {(state.sankeyData != null || delayed) && state.helpText && state.helpText != "" && (
                <EnergipresentationAccordion
                    key={"accordion_" + state.blockId}
                    title={state.helpTitle}
                    text={addSpecificHelpText(state.helpText)}
                    modelStyle={state.helpModelStyle}
                />
            )}
        </React.Fragment>
    );
};
export default EnergipresentationSankey;