import * as React from 'react';

interface iEnergipresentationAccordion {
    title: string;
    text: string;
    modelStyle?: string | "";
};

const EnergipresentationAccordion = (props: iEnergipresentationAccordion) => {

    return (
        <React.Fragment>
            <div className={"Block expandable-block energipresentation-accordion purple-bg-style expanded " + props.modelStyle}>
                <div className="header">
                    <div className="plus">+</div>
                    <div className="minus">&#8722;</div>
                    <h2>{props.title}</h2>
                </div>
                <div className="body">
                    <div className="bodytext is-block" dangerouslySetInnerHTML={{ __html: props.text }} />
                </div>
            </div>
        </React.Fragment>
    );
};
export default EnergipresentationAccordion;