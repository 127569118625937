import React, { useState, useEffect } from 'react';
import HelpText from './HelpText';

interface Cell {
    StringValue?: string;
    NumberValue?: string;
}

interface Row {
    Cells: Cell[];
}

interface KalkylElefterfraganTableProps {
    data: { Rows: any[]; Name: string };
    handleDemandInputChange: (value: string, cell: number, row: number) => void;
    helpTexts: any;
    unit: string;
}

const KalkylElefterfraganTable: React.FC<KalkylElefterfraganTableProps> = ({ data, handleDemandInputChange, helpTexts, unit }) => {
    useEffect(() => {
        setLocalData(data);
    }, [data]);

    const [localData, setLocalData] = useState(data);

    const rows = localData.Rows;
    const name = localData.Name;
    const [thead, ...tbody] = rows;

    const columnSums = Array.from({ length: thead.Cells.length }, () => 0);
    const columnSumsUpperRange = Array.from({ length: thead.Cells.length }, () => 0);
    tbody.forEach(row => {
        row.Cells.slice(1).forEach((cell, columnIndex) => {
            if (cell.NumberValue) {
                columnSums[columnIndex + 1] += parseFloat(cell.NumberValue);
                if (cell.NumberValueUpperRange && cell.NumberValueUpperRange != cell.NumberValue) {
                    columnSumsUpperRange[columnIndex + 1] += parseFloat(cell.NumberValueUpperRange);
                } else {
                    columnSumsUpperRange[columnIndex + 1] += parseFloat(cell.NumberValue);
                }
            }
        });
    });

    function useDebounce(callback: Function, delay: number) {
        const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

        useEffect(() => {
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        }, []);

        const debouncedCallback = (...args: any[]) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                callback(...args);
            }, delay);
        };

        return debouncedCallback;
    }

    const debouncedHandleInputChange = useDebounce((newValue, cellIndex, rowIndex) => {
        handleDemandInputChange(newValue.replace(',', '.'), cellIndex, rowIndex)
    }, 600);


    const handleInputChange = (newValue: string, cellIndex: number, rowIndex: number) => {
        const updatedRows = rows.map((row, rIndex) => {
            if (rIndex === rowIndex + 1) {
                const updatedCells = row.Cells.map((cell, cIndex) => {
                    if (cIndex === cellIndex) {
                        return { ...cell, NumberValue: newValue };
                    }
                    return cell;
                });
                return { ...row, Cells: updatedCells };
            }
            return row;
        });
        setLocalData({ ...localData, Rows: updatedRows }); 
        debouncedHandleInputChange(newValue, cellIndex, rowIndex);
    };

    const addThousandSeparators = (num: number): string => {
         const parts = num.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        if (parts.length > 1) {
            return parts.join('.');
        }
        return parts[0];
    };

    const hasHelpText = (key) => {
        return (key in helpTexts) && helpTexts[key].length > 0;
    };

    const handleClick = (e) => {
        e.target.select();
    };

    const handleKeyPress = (e) => {
        if (!/[0-9.,\s]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text');
        if (!/^[0-9.,\s]*$/.test(pasteData)) {
            e.preventDefault();
        }
    };
    return (
        <div className='table-container  demand-table'>
            <table className='rs_content'>
                <thead className='hide-mobile'>
                    <tr>
                        {thead.Cells.map((cell, index) => (
                            <th key={index}>
                                {cell.StringValue ? cell.StringValue : ''}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tbody.map((row, rowIndex) => {
                        const includesIndustrisektorn = row.Cells.some(cell => cell.StringValue && cell.StringValue.includes('Industri'));
                        const isUpcoming = row.Cells.some(cell => cell.StringValue && cell.StringValue.includes('kommande'));

                        return (
                            <tr key={rowIndex}>
                                {row.Cells.map((cell, cellIndex) => (
                                    <td key={cellIndex}>
                                        <span className="mobile-label bold">
                                            {thead.Cells[cellIndex].StringValue}:
                                        </span>
                                        {includesIndustrisektorn && isUpcoming && (cellIndex === 2 || cellIndex === 3) ? (
                                            <input
                                                min="0"
                                                type="text"
                                                value={cell.NumberValue}
                                                onChange={(e) => handleInputChange(e.target.value, cellIndex, rowIndex)}
                                                onClick={handleClick}
                                                onKeyUp={handleKeyPress}
                                                onPaste={handlePaste}
                                            />
                                        ) : (
                                            <>
                                                    <span>{cell.StringValue ? cell.StringValue : addThousandSeparators(parseFloat(cell.NumberValue.toFixed(2)))}
                                                        {cell.NumberValueUpperRange && parseFloat(cell.NumberValueUpperRange) !== parseFloat(cell.NumberValue) ? <>-<br />{addThousandSeparators(parseFloat(cell.NumberValueUpperRange.toFixed(2)))}</>
                                                            : ''
                                                        }
                                                    </span>
                                                {hasHelpText(cell.StringValue) &&
                                                    <HelpText helpText={helpTexts[cell.StringValue]} />
                                                }
                                            </>
                                        )}
                                        <span className="mobile-label"> {unit}</span>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                    <tr className="sum">
                        <td>Summa</td>
                        {columnSums.slice(1).map((sum, index) => (
                            <td key={index + 1}>
                                <span className="mobile-label bold">
                                    {thead.Cells[index + 1].StringValue}:
                                </span>
                                {index === columnSums.length - 2 ? unit : sum !== 0 ? addThousandSeparators(parseFloat(sum.toFixed(2))) : ''}
                                {parseFloat(columnSumsUpperRange[index + 1].toFixed(2)) === parseFloat(sum.toFixed(2))
                                    ? ''
                                    : <>-<br />{addThousandSeparators(parseFloat(columnSumsUpperRange[index + 1].toFixed(2)))}</>
                                }
                                {

                                    <span className="mobile-label"> {unit}</span>
                                }
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default KalkylElefterfraganTable;
