import * as React from "react";
import * as ReactDOM from "react-dom";

import ElstodsFormular from './ElstodsFormular';

$(document).ready(function () {
    if (document.getElementsByClassName('elstodsformular-block-react-container')) {
        let elstodsBlocks = document.getElementsByClassName('elstodsformular-block-react-container');

        for (let i = 0; i < elstodsBlocks.length; i++) {
            let currentBlock = elstodsBlocks[i];
            let titleConsumption = currentBlock.getAttribute("data-title-consumption");
            let titleCost = currentBlock.getAttribute("data-title-cost");
            let titleCalculation = currentBlock.getAttribute("data-title-calculation");
            let buttonTitle = currentBlock.getAttribute("data-button-title");
            let resetButtonTitle = currentBlock.getAttribute("data-reset-button-title");
            let labelAnnualTurnover = currentBlock.getAttribute("data-label-annual-turnover");
            let labelPeriodYear2 = currentBlock.getAttribute("data-label-period-year2");
            let labelPeriodYear1 = currentBlock.getAttribute("data-label-period-year1");
            let labelFullYear1 = currentBlock.getAttribute("data-label-full-year1");
            let labelQuota = currentBlock.getAttribute("data-label-quota");
            let labelEligibleUse = currentBlock.getAttribute("data-label-eligible-use");
            let labelEligibleCost = currentBlock.getAttribute("data-label-eligible-cost");
            let explanationQuota = currentBlock.getAttribute("data-explanation-quota");
            let explanationEligibleUse = currentBlock.getAttribute("data-explanation-eligible-use");
            let explanationEligibleCost = currentBlock.getAttribute("data-explanation-eligible-cost");
            let suffixCurrency = currentBlock.getAttribute("data-suffix-currency");
            let suffixElectricity = currentBlock.getAttribute("data-suffix-electricity");
            let suffixQuota = currentBlock.getAttribute("data-suffix-quota");
            let showAsHorizontalString = currentBlock.getAttribute("data-show-as-horizontal");
            let showAsHorizontal = false;
            let preSummary = currentBlock.getAttribute("data-presummary");
            let postSummary = currentBlock.getAttribute("data-postsummary");
            let negativeSummary = currentBlock.getAttribute("data-negativesummary");
            let hideQuotaString = currentBlock.getAttribute("data-hide-quota");
            let hideEligibleCostString = currentBlock.getAttribute("data-hide-eligible-cost");
            let hideEligibleUseString = currentBlock.getAttribute("data-hide-eligible-use");

            let hideQuota = false;
            let hideEligibleCost = false;
            let hideEligibleUse = false;


            if (showAsHorizontalString !== null && showAsHorizontalString !== undefined && showAsHorizontalString.toLocaleLowerCase() === "true") {
                showAsHorizontal = true;
            }

            if (hideQuotaString !== null && hideQuotaString !== undefined && hideQuotaString.toLocaleLowerCase() === "true") {
                hideQuota = true;
            }

            if (hideEligibleCostString !== null && hideEligibleCostString !== undefined && hideEligibleCostString.toLocaleLowerCase() === "true") {
                hideEligibleCost = true;
            }

            if (hideEligibleUseString !== null && hideEligibleUseString !== undefined && hideEligibleUseString.toLocaleLowerCase() === "true") {
                hideEligibleUse = true;
            }

            ReactDOM.render(<ElstodsFormular
                showAsHorizontal={showAsHorizontal}
                titleConsumption={titleConsumption}
                titleCost={titleCost}
                buttonTitle={buttonTitle}
                resetButtonTitle={resetButtonTitle}
                labelAnnualTurnover={labelAnnualTurnover}
                labelPeriodYear2={labelPeriodYear2}
                labelPeriodYear1={labelPeriodYear1}
                labelFullYear1={labelFullYear1}
                labelQuota={labelQuota}
                labelEligibleUse={labelEligibleUse}
                labelEligibleCost={labelEligibleCost}
                explanationQuota={explanationQuota}
                explanationEligibleUse={explanationEligibleUse}
                explanationEligibleCost={explanationEligibleCost}
                suffixCurrency={suffixCurrency}
                suffixElectricity={suffixElectricity}
                titleCalculation={titleCalculation}
                preSummary={preSummary}
                postSummary={postSummary}
                negativeSummary={negativeSummary}
                suffixQuota={suffixQuota}
                hideQuota={hideQuota}
                hideEligibleCost={hideEligibleCost}
                hideEligibleUse={hideEligibleUse}
            />, currentBlock);
        }
    }
});